const dashboardReducer = (state, action) => {
  switch (action.type) {
    case 'projectsDataUpdated':
      return { ...state, projectsData: action.payload };
    case 'activeProjectUpdated':
      return { ...state, activeProject: action.payload };
    case 'activeProjectFilesDataUpdated':
      return { ...state, activeProjectFilesData: action.payload };
    case 'activeProjectPaymentDataUpdated':
      return { ...state, activeProjectPaymentData: action.payload };
    case 'reset':
      return {
        projectsData: null,
        activeProject: null,
        activeProjectFilesData: null,
        activeProjectPaymentData: null
      };
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};

// TODO: Add a reset action when the user logs out / session expires

export default dashboardReducer;
