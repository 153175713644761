import { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import DashboardContext from '@Contexts/dashboard/DashboardContext';
import {
  getProject,
  updateProject,
  deleteProject,
  getProjectFiles,
  getProjectPayment,
  uploadProjectFile,
  refundProjectPayment,
  downloadFile,
  updateFile,
  deleteFile
} from '@Contexts/dashboard/DashboardActions';

import { getBadgeColor, getColor } from '@Utils/helpers';
import {
  PROJECT_STATUSES,
  MAX_PROJECT_FILES,
  PROJECT_STATUSES_PREVENT_CHANGES,
  PAYMENT_STATUSES,
  FILE_STATUSES
} from '@Utils/constants';

import { toast } from 'react-toastify';

import Loader from '@Components/ui/Loader';
import Card from '@Components/ui/Card';

import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { FaCloudDownloadAlt, FaRegTrashAlt } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';

const AdminProjectDetails = () => {
  const { dispatch: authDispatch } = useContext(AuthContext);
  const {
    activeProject,
    activeProjectFilesData,
    activeProjectPaymentData,
    dispatch: dashboardDispatch
  } = useContext(DashboardContext);

  const [formData, setFormData] = useState({ file: null });
  const [isFetchingProject, setIsFetchingProject] = useState(true);
  const [isDeletingProject, setIsDeletingProject] = useState(false);
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);
  const [rndFileKey, setRndFileKey] = useState(Math.random()); //? This is used to reset the file input after a file is uploaded.
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [fileToDownload, setFileToDownload] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const deleteProjectModalRef = useRef();
  const downloadFileModalRef = useRef();
  const deleteFileModalRef = useRef();
  const loadingModalRef = useRef();

  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeProject === null || activeProject.id !== projectId) {
      const fetchProject = async () => {
        try {
          const fetchedProject = await getProject(projectId);

          setStatusMessage(fetchedProject.statusMessage || '');

          dashboardDispatch({ type: 'activeProjectUpdated', payload: fetchedProject });
        } catch (err) {
          //? Error is handled by the interceptor.
          navigate('/dashboard/projects');
        } finally {
          setIsFetchingProject(false);
        }
      };

      fetchProject();
    } else if (activeProject && (activeProjectFilesData === null || activeProjectFilesData.projectId !== projectId)) {
      setIsFetchingProject(false); //? This is needed because activeProject is already set (either fetched or passed).

      const fetchFiles = async () => {
        try {
          const fetchedFilesData = await getProjectFiles(
            activeProject.userId,
            activeProject.id,
            undefined,
            MAX_PROJECT_FILES
          );

          fetchedFilesData.projectId = activeProject.id;

          dashboardDispatch({
            type: 'activeProjectFilesDataUpdated',
            payload: fetchedFilesData
          });
        } catch (err) {
          dashboardDispatch({
            type: 'activeProjectFilesDataUpdated',
            payload: {
              files: [],
              currentPage: 1,
              pages: 1,
              totalItems: 0,
              projectId: activeProject.id
            }
          });
        } finally {
          setIsFetchingFiles(false);
        }
      };

      fetchFiles();
    } else {
      setIsFetchingProject(false);
      setIsFetchingFiles(false);
    }
  }, [activeProject, projectId, dashboardDispatch, activeProjectFilesData, navigate]);

  useEffect(() => {
    if (
      activeProject &&
      activeProject.id === projectId &&
      activeProject.currentPriceInCents > 0 &&
      activeProject.status !== PROJECT_STATUSES.open &&
      (!activeProjectPaymentData || activeProjectPaymentData.projectId !== projectId)
    ) {
      const fetchProjectPayment = async () => {
        try {
          const fetchedProjectPayment = await getProjectPayment(activeProject.id);

          dashboardDispatch({ type: 'activeProjectPaymentDataUpdated', payload: fetchedProjectPayment });
        } catch (err) {
          //? Do nothing, error is handled by the interceptor.
        }
      };

      fetchProjectPayment();
    } else if (
      activeProject &&
      activeProject.id === projectId &&
      (activeProject.currentPriceInCents === 0 || activeProject.status === PROJECT_STATUSES.open) &&
      activeProjectPaymentData
    ) {
      dashboardDispatch({ type: 'activeProjectPaymentDataUpdated', payload: null });
    }
  }, [activeProject, activeProjectPaymentData, projectId, dashboardDispatch]);

  if (isFetchingProject) return <Loader customText='Retrieving project' />;

  const handleDeleteProjectClick = () => {
    if (PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)) return;

    deleteProjectModalRef?.current?.showModal();
  };

  const handleDeleteProject = async () => {
    if (PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)) return;

    setIsDeletingProject(true);

    try {
      await deleteProject(activeProject.id);

      dashboardDispatch({ type: 'projectsDataUpdated', payload: null }); //? Forces a refetch of the projects data.

      toast.success('Project successfully deleted.');

      navigate('/dashboard/projects');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      deleteProjectModalRef?.current?.close?.();
      setIsDeletingProject(false);
    }
  };

  const handleProjectStatusChange = async ({ target }) => {
    setIsFetchingProject(true);

    const newStatus = target.value;

    if (!newStatus) return;

    if (newStatus === activeProject?.status) {
      toast.warn(`The project is already in ${newStatus} status.`);
      setIsFetchingProject(false);
      return;
    }

    let allowedStatuses = [];

    switch (activeProject.status) {
      case PROJECT_STATUSES.open:
        allowedStatuses = [
          PROJECT_STATUSES.paid,
          PROJECT_STATUSES.processing,
          PROJECT_STATUSES.completed,
          PROJECT_STATUSES.cancelled
        ];
        break;
      case PROJECT_STATUSES.paid:
        allowedStatuses = [PROJECT_STATUSES.processing, PROJECT_STATUSES.completed, PROJECT_STATUSES.cancelled];
        break;
      case PROJECT_STATUSES.processing:
        allowedStatuses = [PROJECT_STATUSES.paid, PROJECT_STATUSES.completed, PROJECT_STATUSES.cancelled];
        break;
      case PROJECT_STATUSES.completed:
        allowedStatuses = [PROJECT_STATUSES.paid, PROJECT_STATUSES.processing, PROJECT_STATUSES.cancelled];
        break;
      case PROJECT_STATUSES.cancelled:
      case PROJECT_STATUSES.pending:
      default:
        break;
    }

    if (allowedStatuses.length === 0 || !allowedStatuses.includes(newStatus)) {
      toast.warn(`Project cannot be changed to ${newStatus} status.`);
      setIsFetchingProject(false);
      return;
    }

    try {
      const updatedProject = await updateProject(projectId, { status: newStatus });

      dashboardDispatch({ type: 'activeProjectUpdated', payload: updatedProject });

      toast.success('Project status successfully updated.');
    } catch (err) {
      //? Error is handled by the interceptor.
    } finally {
      setIsFetchingProject(false);
    }
  };

  const handleStatusMessageUpdate = async () => {
    const newStatusMessage = statusMessage.trim();

    if (newStatusMessage === '') return;

    setIsFetchingProject(true);

    if (newStatusMessage === activeProject.statusMessage) {
      toast.warn('Status message already up to date.');
      setIsFetchingProject(false);
      return;
    }

    try {
      const updatedProject = await updateProject(projectId, { statusMessage: newStatusMessage });

      dashboardDispatch({ type: 'activeProjectUpdated', payload: updatedProject });

      toast.success('Status message successfully updated.');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      setIsFetchingProject(false);
    }
  };

  const handleProjectRefund = async () => {
    if (!window.confirm('Do you really want to refund this project completely?')) return;

    setIsFetchingProject(true);

    try {
      const updatedPaymentData = await refundProjectPayment(projectId);

      dashboardDispatch({ type: 'activeProjectPaymentDataUpdated', payload: updatedPaymentData });

      toast.success('Project payment successfully refunded.');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      setIsFetchingProject(false);
    }
  };

  const handleFormChange = ({ target }) => {
    if (target.id === 'file') {
      setFormData(prev => ({ ...prev, file: target.files[0] }));
    } else {
      setFormData(prev => ({ ...prev, [target.id]: target.value }));
    }
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    if (activeProject.status !== PROJECT_STATUSES.processing) return;

    authDispatch({ type: 'preventSessionTimeoutUpdated', payload: true });
    setIsUploading(true);

    loadingModalRef?.current?.showModal();

    const actualFormData = new FormData();

    Object.keys(formData).forEach(key => {
      actualFormData.append(key, formData[key]);
    });

    const onProgressCb = progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      setUploadProgress(percentCompleted);
    };

    try {
      const uploadedFile = await uploadProjectFile(
        activeProject.userId,
        activeProject.id,
        actualFormData,
        onProgressCb
      );
      const fetchedProject = await getProject(projectId);

      const updatedActiveProjectFilesData = {
        ...activeProjectFilesData,
        files: [uploadedFile, ...activeProjectFilesData.files],
        totalItems: activeProjectFilesData.totalItems + 1
      };

      setStatusMessage(fetchedProject.statusMessage || '');

      dashboardDispatch({ type: 'activeProjectFilesDataUpdated', payload: updatedActiveProjectFilesData });
      dashboardDispatch({ type: 'activeProjectUpdated', payload: fetchedProject }); //? Update project with new data

      toast.success('File successfully uploaded.');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      setFormData({ file: null });
      authDispatch({ type: 'preventSessionTimeoutUpdated', payload: false });
      setUploadProgress(0);
      setRndFileKey(Math.random()); //? This is used to reset the file input after a file is uploaded.
      setIsUploading(false);
      loadingModalRef?.current?.close?.();
    }
  };

  const handleFileStatusChange = async (newStatus, file) => {
    setIsFetchingFiles(true);

    if (file.status === newStatus) {
      toast.warn(`File already in ${newStatus} status.`);
      setIsFetchingFiles(false);
      return;
    }

    try {
      const updatedFile = await updateFile(file.id, { status: newStatus });

      const updatedActiveProjectFilesData = {
        ...activeProjectFilesData,
        files: activeProjectFilesData.files.map(f => (f.id === updatedFile.id ? updatedFile : f))
      };

      dashboardDispatch({ type: 'activeProjectFilesDataUpdated', payload: updatedActiveProjectFilesData });

      toast.success('File status successfully updated.');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      setIsFetchingFiles(false);
    }
  };

  const handleDeleteFileClick = file => {
    setFileToDelete(file);

    deleteFileModalRef?.current?.showModal();
  };

  const handleDeleteFile = async () => {
    setIsDeletingFile(true);

    try {
      await deleteFile(fileToDelete.id);
      const fetchedProject = await getProject(projectId);

      const updatedActiveProjectFilesData = {
        ...activeProjectFilesData,
        files: activeProjectFilesData.files.filter(file => file.id !== fileToDelete.id),
        totalItems: activeProjectFilesData.totalItems - 1
      };

      setStatusMessage(fetchedProject.statusMessage || '');

      dashboardDispatch({ type: 'activeProjectFilesDataUpdated', payload: updatedActiveProjectFilesData });
      dashboardDispatch({ type: 'activeProjectUpdated', payload: fetchedProject }); //? Update project with new data

      toast.success('File successfully deleted.');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      deleteFileModalRef?.current?.close?.();
      setIsDeletingFile(false);
      setFileToDelete(null);
    }
  };

  const handleDownloadFileClick = async file => {
    setFileToDownload(file);
    downloadFileModalRef?.current?.showModal();

    const onProgressCb = progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      setDownloadProgress(percentCompleted);
    };

    try {
      const downloadedFile = await downloadFile(file.id, onProgressCb);
      const url = window.URL.createObjectURL(downloadedFile);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', file.displayName);

      document.body.appendChild(link);

      link.click();

      toast.success('File successfully downloaded.');

      setTimeout(function () {
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 200);
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      downloadFileModalRef?.current?.close?.();
      setFileToDownload(null);
    }
  };

  const { files, totalItems } = activeProjectFilesData || {};
  const { file } = formData;

  const statusColor = getColor(activeProject.status);

  return (
    <>
      <Card className='mb-6' customWidthClasses='w-11/12' customContentClasses='items-center'>
        <div className='w-full flex flex-col sm:flex-row items-center justify-between mb-3'>
          <button
            type='button'
            className='btn btn-outline btn-xs mb-3 sm:mb-0'
            onClick={() => navigate('/dashboard/projects')}
          >
            <IoMdArrowRoundBack />
          </button>

          <div className='flex flex-row items-center mb-3 sm:mb-0'>
            <h1 className='text-center text-lg font-bold mr-2'>{activeProject.name}</h1>

            <select
              className={`select select-sm select-${statusColor} w-full max-w-fit text-center`}
              value={activeProject?.status}
              onChange={handleProjectStatusChange}
              disabled={isFetchingProject}
            >
              {Object.values(PROJECT_STATUSES).map(status => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>

          <button
            className='btn btn-outline btn-error btn-xs'
            disabled={PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)}
            onClick={handleDeleteProjectClick}
          >
            <FaRegTrashAlt />
          </button>
        </div>

        <div className='w-full flex flex-row items-center justify-center mb-3'>
          <h3 className='text-center'>{activeProject.description}</h3>
        </div>

        {activeProjectPaymentData && (
          <div className='w-full flex flex-row items-center justify-center mb-3'>
            <h3 className='mr-3'>Payment:</h3>

            <div className={`badge badge-outline ${getBadgeColor(activeProjectPaymentData.status)}`}>
              {activeProjectPaymentData.status}
            </div>

            {activeProjectPaymentData.status === PAYMENT_STATUSES.succeeded && (
              <button onClick={handleProjectRefund} type='button' className='btn btn-xs btn-outline btn-error ml-3'>
                <FaMoneyBillTransfer />
                Refund
              </button>
            )}
          </div>
        )}

        <div className='w-full flex flex-col items-center justify-center mb-3'>
          <textarea
            className='textarea textarea-bordered w-72 sm:w-96'
            placeholder='Status message'
            value={statusMessage}
            onChange={e => setStatusMessage(e.target.value)}
          ></textarea>

          <button
            className='btn btn-xs btn-info btn-outline mt-3'
            onClick={handleStatusMessageUpdate}
            disabled={statusMessage.trim() === '' || statusMessage.trim() === activeProject?.statusMessage}
          >
            Update
          </button>
        </div>

        <div className='w-full flex flex-row items-center justify-between'>
          <h3 className='text-left'>Created On: {new Date(activeProject.createdDate).toDateString()}</h3>
          <h3 className='text-right'>Last Updated: {new Date(activeProject.updatedDate).toDateString()}</h3>
        </div>

        <dialog ref={deleteProjectModalRef} id='delete_project_modal' className='modal modal-bottom sm:modal-middle'>
          <div className='modal-box'>
            <form method='dialog'>
              <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'>✕</button>
            </form>

            <h3 className='text-center font-bold text-lg mb-3'>Delete {activeProject.name}</h3>

            <p className='text-center mb-3'>
              This action is permanent! All data and files linked to this project will also be deleted.
            </p>

            {!isDeletingProject && (
              <div className='flex justify-around items-center'>
                <button className='btn btn-outline btn-error' onClick={handleDeleteProject}>
                  Delete Project
                </button>
                <button
                  className='btn btn-outline btn-neutral'
                  onClick={() => deleteProjectModalRef?.current?.close?.()}
                >
                  Cancel
                </button>
              </div>
            )}
            {isDeletingProject && <Loader customText='Deleting project' />}
          </div>
        </dialog>
      </Card>

      <Card customWidthClasses='w-11/12'>
        {isFetchingFiles && <Loader customText='Retrieving files' />}

        {!isFetchingFiles && (
          <>
            <h1 className='text-center font-bold'>
              {totalItems > 0
                ? `${totalItems} File${totalItems > 1 ? 's' : ''}`
                : 'This project does not have any files yet.'}
            </h1>

            {totalItems > 0 && (
              <>
                <dialog
                  ref={downloadFileModalRef}
                  id='download_file_modal'
                  className='modal modal-bottom sm:modal-middle'
                >
                  <div className='modal-box'>
                    <h3 className='text-center font-bold text-lg'>Downloading {fileToDownload?.displayName}</h3>
                    <p className='text-center mt-3'>Please, remain on this page until the end of the upload.</p>
                    <p className='text-center mt-3'>This window will automatically close on completion.</p>
                    <p className='mt-3 font-bold flex flex-row justify-center items-end'>
                      {downloadProgress === 0 && (
                        <>
                          <span>Preparing file</span>
                          <span className='loading loading-dots loading-xs mx-2'></span>
                          <span>This may take a while for larger files.</span>
                        </>
                      )}
                      {downloadProgress > 0 && <>{downloadProgress}% Downloaded</>}
                    </p>
                    <progress className='progress progress-info w-full' value={downloadProgress} max='100'></progress>
                  </div>
                </dialog>

                {/* TODO: Add pagination */}
                <div className='overflow-x-scroll max-w-full'>
                  <table className='table table-sm sm:table-md xl:table-lg text-center'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Nodes</th>
                        <th>Focus Areas</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {files.map(file => (
                        <tr key={file.id}>
                          <td>{file.displayName}</td>
                          <td>{file.nodes || 'N/A'}</td>
                          <td className='whitespace-pre-wrap'>{file.focusAreas || 'N/A'}</td>
                          <td>{new Date(file.updatedDate).toDateString()}</td>
                          <td>
                            <select
                              className={`select select-sm select-${getColor(
                                file.status
                              )} w-full max-w-fit text-center`}
                              value={file?.status}
                              onChange={e => handleFileStatusChange(e?.target?.value, file)}
                              disabled={isFetchingFiles}
                            >
                              {Object.values(FILE_STATUSES).map(status => (
                                <option key={status} value={status}>
                                  {status}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-outline btn-info btn-xs'
                              onClick={() => handleDownloadFileClick(file)}
                            >
                              <FaCloudDownloadAlt />
                            </button>
                          </td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-outline btn-error btn-xs'
                              disabled={
                                PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject?.status) && file.isUserFile
                              }
                              onClick={() => handleDeleteFileClick(file)}
                            >
                              <FaRegTrashAlt />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <dialog ref={deleteFileModalRef} id='delete_file_modal' className='modal modal-bottom sm:modal-middle'>
                  <div className='modal-box'>
                    <form method='dialog'>
                      <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'>✕</button>
                    </form>
                    <h3 className='text-center font-bold text-lg mb-3'>Delete {fileToDelete?.displayName}</h3>
                    <p className='text-center mb-3'>This action is permanent!</p>
                    <p className='text-center mb-3'>
                      If you wish to keep the file, please download it before deleting it.
                    </p>

                    {!isDeletingFile && (
                      <div className='flex justify-around items-center'>
                        <button className='btn btn-outline btn-error' onClick={handleDeleteFile}>
                          Delete File
                        </button>
                        <button
                          className='btn btn-outline btn-neutral'
                          onClick={() => deleteFileModalRef?.current?.close?.()}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                    {isDeletingFile && <Loader customText='Deleting file' />}
                  </div>
                </dialog>
              </>
            )}
          </>
        )}
      </Card>

      {!isFetchingFiles &&
        totalItems < MAX_PROJECT_FILES / 2 &&
        activeProject.status === PROJECT_STATUSES.processing && (
          <Card customWidthClasses='w-11/12' className='mt-6'>
            <dialog ref={loadingModalRef} id='loading_modal' className='modal modal-bottom sm:modal-middle'>
              <div className='modal-box'>
                <h3 className='text-center font-bold text-lg'>Uploading file</h3>
                <p className='text-center mt-3'>Please, remain on this page until the end of the upload.</p>
                <p className='text-center mt-3'>This window will automatically close on completion.</p>
                <p className='mt-3 font-bold flex flex-row justify-center items-end'>
                  {uploadProgress >= 99 && (
                    <>
                      <span>Finishing</span>
                      <span className='loading loading-dots loading-xs mx-2'></span>
                      <span>This may take a while for larger files.</span>
                    </>
                  )}
                  {uploadProgress < 99 && <>{uploadProgress}% Done</>}
                </p>
                <progress className='progress progress-info w-full' value={uploadProgress} max='100'></progress>
              </div>
            </dialog>

            <h1 className='text-center font-bold'>Upload File</h1>
            <form className='w-full sm:w-2/3 xl:w-1/2' onSubmit={handleFormSubmit}>
              <input
                type='file'
                id='file'
                className='file-input file-input-bordered w-full mb-3'
                key={rndFileKey}
                onChange={handleFormChange}
              />

              <button type='submit' className='btn btn-primary w-full mb-3' disabled={!file || isUploading}>
                {isUploading ? <span className='loading loading-dots loading-md'></span> : 'Upload'}
              </button>
            </form>
          </Card>
        )}
    </>
  );
};

export default AdminProjectDetails;
