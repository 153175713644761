import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DashboardContext from '@Contexts/dashboard/DashboardContext';
import { getProjects } from '@Contexts/dashboard/DashboardActions';

import { getBadgeColor } from '@Utils/helpers';
import { MAX_SHORT_DESCRIPTION_LENGTH } from '@Utils/constants';

import Loader from '@Components/ui/Loader';
import Card from '@Components/ui/Card';

const AdminProjects = () => {
  const [searchData, setSearchData] = useState({ search: '', filter: 'default', order: 'default' });
  const [debouncedSearchData, setDebouncedSearchData] = useState(null);
  const [previousSearchData, setPreviousSearchData] = useState({});
  const [isFetchingProjects, setIsFetchingProjects] = useState(true);

  const { projectsData, dispatch: dashboardDispatch } = useContext(DashboardContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectsData === null) {
      const fetchProjects = async () => {
        try {
          const fetchedProjectsData = await getProjects();

          dashboardDispatch({
            type: 'projectsDataUpdated',
            payload: getPayloadFromProjectsData(fetchedProjectsData, projectsData)
          });
        } catch (err) {
          dashboardDispatch({
            type: 'projectsDataUpdated',
            payload: {
              projectsPerPages: [],
              currentPage: 1,
              pages: 1,
              totalItems: 0
            }
          });
        } finally {
          setIsFetchingProjects(false);
        }
      };

      fetchProjects();
    } else {
      setIsFetchingProjects(false);
    }
  }, [projectsData, dashboardDispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchData(searchData);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchData]);

  useEffect(() => {
    if (!debouncedSearchData) return;

    let { search, filter, order } = debouncedSearchData;

    search = search.trim();

    if (
      (search === '' && (previousSearchData.search || '') === '') ||
      (search === (previousSearchData.search || '') &&
        filter === previousSearchData.filter &&
        order === previousSearchData.order)
    )
      return;

    setIsFetchingProjects(true);

    const fetchProjects = async () => {
      try {
        const updatedProjectsData = await getProjects(undefined, undefined, undefined, order, filter, search);

        setPreviousSearchData({ search, filter, order });

        dashboardDispatch({
          type: 'projectsDataUpdated',
          payload: getPayloadFromProjectsData(updatedProjectsData)
        });
      } catch (err) {
        //? Do nothing, error is handled by the interceptor.
      } finally {
        setIsFetchingProjects(false);
      }
    };

    fetchProjects();
  }, [debouncedSearchData, dashboardDispatch, previousSearchData]);

  const handleSearchDataChange = e => {
    setSearchData({ ...searchData, [e.target.id]: e.target.value === 'default' ? undefined : e.target.value });
  };

  const { projectsPerPages, currentPage, pages, totalItems } = projectsData || {};
  const projects = projectsPerPages?.[currentPage - 1] || [];

  const handlePageChange = async direction => {
    let pageToFetch = null;

    if (direction === 'previous' && currentPage > 1) {
      pageToFetch = currentPage - 1;
    } else if (direction === 'next' && currentPage < pages) {
      pageToFetch = currentPage + 1;
    }

    if (pageToFetch === null) return;

    if (projectsData?.projectsPerPages[pageToFetch - 1]) {
      dashboardDispatch({
        type: 'projectsDataUpdated',
        payload: {
          ...projectsData,
          currentPage: pageToFetch
        }
      });
    } else {
      setIsFetchingProjects(true);

      try {
        const { search, filter, order } = debouncedSearchData || {};

        const updatedProjectsData = await getProjects(pageToFetch, undefined, undefined, order, filter, search);

        dashboardDispatch({
          type: 'projectsDataUpdated',
          payload: getPayloadFromProjectsData(updatedProjectsData, projectsData)
        });
      } catch (err) {
        //? Do nothing, error is handled by the interceptor.
      } finally {
        setIsFetchingProjects(false);
      }
    }
  };

  const handleViewProjectClick = project => {
    dashboardDispatch({ type: 'activeProjectUpdated', payload: project });
    navigate(`${project.id}`);
  };

  return (
    <>
      <Card className='mb-6' customWidthClasses='w-11/12' customContentClasses='flex flex-col items-center'>
        <div className='join'>
          <input
            id='search'
            onChange={handleSearchDataChange}
            value={searchData.search}
            className='input input-bordered join-item'
            placeholder='Search'
          />

          <select
            id='filter'
            onChange={handleSearchDataChange}
            value={searchData.filter}
            className='select select-bordered join-item'
          >
            <option value='default'>Default Filter</option>
            <option value='status'>Status</option>
            <option value='name'>Name</option>
            <option value='description'>Description</option>
          </select>

          <select
            id='order'
            onChange={handleSearchDataChange}
            value={searchData.order}
            className='select select-bordered join-item'
          >
            <option value='default'>Default Order</option>
            <option value='asc'>Ascending</option>
            <option value='desc'>Descending</option>
          </select>
        </div>
      </Card>

      <Card customWidthClasses='w-11/12'>
        {isFetchingProjects && <Loader />}

        {!isFetchingProjects && (
          <>
            <h1 className='font-bold text-lg'>
              {totalItems > 0 ? `${totalItems} Project${totalItems > 1 ? 's' : ''}` : 'There are not projects yet.'}
            </h1>

            {totalItems > 0 && (
              <>
                <div className='overflow-x-auto max-w-full'>
                  <table className='table table-sm sm:table-md xl:table-lg text-center'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th className='hidden md:table-cell'>Updated On</th>
                        <th className='hidden md:table-cell'>Created On</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {projects.map(project => (
                        <tr key={project.id}>
                          <td>{project.name}</td>
                          <td>
                            {project.description.length >= MAX_SHORT_DESCRIPTION_LENGTH
                              ? project.description.substring(0, MAX_SHORT_DESCRIPTION_LENGTH).trim() + '...'
                              : project.description}
                          </td>
                          <td>
                            <div className={`badge badge-outline whitespace-nowrap ${getBadgeColor(project.status)}`}>
                              {project.status}
                            </div>
                          </td>
                          <td className='hidden md:table-cell whitespace-nowrap'>
                            {new Date(project.updatedDate).toDateString()}
                          </td>
                          <td className='hidden md:table-cell whitespace-nowrap'>
                            {new Date(project.createdDate).toDateString()}
                          </td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-outline btn-secondary btn-xs'
                              onClick={() => handleViewProjectClick(project)}
                            >
                              Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className='join'>
                  <button
                    type='button'
                    className='join-item btn btn-xs sm:btn-md btn-outline'
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange('previous')}
                  >
                    «
                  </button>
                  <button type='button' className='join-item btn btn-xs sm:btn-md btn-outline'>
                    Page {currentPage}
                  </button>
                  <button
                    type='button'
                    className='join-item btn btn-xs sm:btn-md btn-outline'
                    disabled={currentPage === pages}
                    onClick={() => handlePageChange('next')}
                  >
                    »
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

//* -- Helpers --
const getPayloadFromProjectsData = (newProjectsData, projectsData) => {
  const projectsPerPages = !projectsData
    ? []
    : Array.isArray(projectsData?.projectsPerPages)
    ? [...projectsData.projectsPerPages]
    : [];

  projectsPerPages[newProjectsData.currentPage - 1] = newProjectsData.projects;

  delete newProjectsData.projects;

  return {
    projectsPerPages,
    ...newProjectsData
  };
};
//* ----

export default AdminProjects;
