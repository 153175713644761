import { Link } from 'react-router-dom';

import { FaTwitter, FaFacebook } from 'react-icons/fa';

import { COMPANY_NAME } from '@Utils/constants';

const Footer = () => {
  return (
    <footer className='footer footer-center p-10 bg-base-200 text-base-content rounded'>
      <div className='grid grid-flow-col gap-4'>
        <Link className='link link-hover' to='/contact'>
          Contact
        </Link>
        <Link className='link link-hover' to='/press'>
          Press Kit
        </Link>
      </div>

      <div>
        <div className='grid grid-flow-col gap-4'>
          <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
            <FaTwitter className='text-xl' />
          </a>

          <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
            <FaFacebook className='text-xl' />
          </a>
        </div>
      </div>

      <div>
        <p>
          Copyright © {new Date().getFullYear()} - All right reserved by {COMPANY_NAME}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
