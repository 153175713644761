import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FaBuffer, FaBars } from 'react-icons/fa';

import { COMPANY_NAME, NAV_MENU_ITEMS } from '@Utils/constants';

const Navbar = ({ onlyHomeLink }) => {
  const { pathname } = useLocation();

  if (onlyHomeLink) {
    return (
      <nav className='navbar sticky top-0 z-30 h-16 bg-base-200'>
        <div className='navbar-start'>
          <Link className='btn btn-ghost normal-case text-xl' to='/'>
            {COMPANY_NAME}
          </Link>
        </div>
      </nav>
    );
  }

  return (
    <nav className='navbar sticky top-0 z-30 h-16 bg-base-200'>
      <div className='navbar-start'>
        <div className='dropdown'>
          <label tabIndex={0} className='btn btn-ghost md:hidden'>
            <FaBars />
          </label>

          <ul tabIndex={0} className='menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-200 rounded-box w-52'>
            <NavItems pathname={pathname} />
          </ul>
        </div>

        <Link className='btn btn-ghost normal-case text-xl' to='/'>
          {COMPANY_NAME}
        </Link>
      </div>

      <div className='navbar-center hidden md:flex'>
        <ul className='menu menu-horizontal p-0 gap-2'>
          <NavItems pathname={pathname} />
        </ul>
      </div>

      <div className='navbar-end'>
        <Link className='btn btn-primary btn-outline normal-case gap-2' to='/auth/login'>
          <FaBuffer className='text-xl' /> Dashboard
        </Link>
      </div>
    </nav>
  );
};

const NavItems = ({ pathname }) => {
  return NAV_MENU_ITEMS.map(({ displayName, key, path }) => (
    <li key={`${key}_main`}>
      <Link className={`rounded ${pathname === path ? 'active' : ''}`} to={path}>
        {displayName}
      </Link>
    </li>
  ));
};

Navbar.propTypes = {
  onlyHomeLink: PropTypes.bool
};

Navbar.defaultProps = {
  onlyHomeLink: false
};

export default Navbar;
