import { Link, useLocation } from 'react-router-dom';

const DashboardDrawer = ({ children, menuItems, handleSidebarToggle, sidebarOpened }) => {
  const { pathname } = useLocation();

  return (
    <div className='drawer lg:drawer-open'>
      <input type='checkbox' className='drawer-toggle' readOnly checked={sidebarOpened} />

      <div className='drawer-content flex flex-col items-center justify-start p-4'>{children}</div>

      <div className='drawer-side top-16 h-[calc(100vh-4rem)]'>
        <label className='drawer-overlay' onClick={handleSidebarToggle}></label>

        <ul className='menu p-4 w-80 min-h-full bg-base-200 text-base-content'>
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link
                className={(item.hasSubRoutes && pathname.includes(item.to)) || pathname === item.to ? 'active' : ''}
                to={item.to}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardDrawer;
