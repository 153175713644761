import { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import { sendResetPasswordEmail, resetPassword } from '@Contexts/auth/AuthActions';

import { PASSWORD_REGEX } from '@Utils/constants';

import { toast } from 'react-toastify';

import Card from '@Components/ui/Card';

const ResetPassword = () => {
  const { user, dispatch } = useContext(AuthContext);

  const [formData, setFormData] = useState({ email: '', password: '', passwordConfirm: '' });
  const [passwordFitsRules, setPasswordFitsRules] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('initial');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && status === 'initial') {
      toast.warn('Already logged in!');
      navigate('/dashboard');
    } else {
      if (searchParams.get('hash') && !['retry', 'reset', 'sent'].includes(status)) setStatus('reset');
    }
  }, [user, status, navigate, searchParams]);

  const handleFormChange = ({ target }) => {
    if (target.id === 'password') {
      setPasswordFitsRules(PASSWORD_REGEX.test(target.value));
    }

    setFormData(prev => ({ ...prev, [target.id]: target.value }));
  };

  const handleEmailFormSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await sendResetPasswordEmail(formData.email);

      setStatus('sent');
    } catch (err) {
      //? No need to display error notification since it is handled in interceptor.
    } finally {
      setFormData({ email: '', password: '', passwordConfirm: '' });
      setIsLoading(false);
    }
  };

  const handlePasswordFormSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedUser = await resetPassword(formData.password, searchParams.get('hash'));

      setIsLoading(false);

      if (updatedUser) {
        toast.success('Password successfully reset. You have been logged in.');

        dispatch({ type: 'userUpdated', payload: updatedUser });

        navigate('/dashboard');
      } else {
        toast.error('An error has occurred. Please try resetting your password again.');
      }
    } catch (err) {
      //? No need to display error notification since it is handled in interceptor.
    } finally {
      setPasswordFitsRules(false);
      setFormData({ email: '', password: '', passwordConfirm: '' });
      setIsLoading(false);
      setStatus('retry');
    }
  };

  const { email, password, passwordConfirm } = formData;

  return (
    <Card>
      {['initial', 'retry'].includes(status) && (
        <div className='text-center w-full'>
          <h1 className='text-2xl mb-3'>Password Reset</h1>

          {status === 'retry' && (
            <>
              <p className='mb-3'>The reset link you used might be expired. Try sending a new one.</p>
              <p className='mb-3'>If the error persist, please contact our support team.</p>
            </>
          )}

          <form className='w-full' onSubmit={handleEmailFormSubmit}>
            <input
              type='email'
              placeholder='Email Address'
              id='email'
              className='input input-bordered w-full mt-1 mb-3'
              onChange={handleFormChange}
              value={email}
            />

            <button type='submit' className='btn btn-primary w-full mb-3' disabled={email.trim() === '' || isLoading}>
              {isLoading ? <span className='loading loading-dots loading-md'></span> : 'Send'}
            </button>

            <p className='text-center text-neutral'>
              Remember your password?{' '}
              <Link to='/auth/login' className='text-accent'>
                Back to login
              </Link>
            </p>
          </form>
        </div>
      )}

      {status === 'sent' && (
        <div className='text-center w-full'>
          <h1 className='text-2xl mb-3'>Email Sent</h1>
          <p className='mb-3'>Password reset email successfully sent.</p>
          <p>Please check your inbox and click the link in the email to set your new password.</p>
        </div>
      )}

      {status === 'reset' && (
        <div className='text-center w-full'>
          <h1 className='text-2xl mb-3'>Password Change</h1>

          <form className='w-full' onSubmit={handlePasswordFormSubmit}>
            <label className='form-control'>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                id='password'
                className={`input input-bordered w-full ${password?.length > 0 && !passwordFitsRules ? '' : 'mb-3'}`}
                value={password}
                onChange={handleFormChange}
              />

              {password?.length > 0 && !passwordFitsRules && (
                <div className='label'>
                  <span className='label-text-alt text-neutral text-left'>
                    <span>Password must match the following criteria:</span>
                    <ul className='mt-1'>
                      <li>- At least 1 lower case letter</li>
                      <li>- At least 1 upper case letter</li>
                      <li>- At least 1 number</li>
                      <li>- At least 1 special character</li>
                      <li>- At least 8 characters long</li>
                    </ul>
                  </span>
                </div>
              )}
            </label>

            <label className='form-control'>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Confirm Password'
                id='passwordConfirm'
                className={`input input-bordered w-full`}
                value={passwordConfirm}
                onChange={handleFormChange}
              />

              <div className='label'>
                <span className='label-text-alt text-neutral'>
                  {passwordConfirm?.length > 0 && passwordConfirm !== password
                    ? 'Password and Confirm password must match!'
                    : ''}
                </span>

                <span className='label-text-alt'>
                  <button
                    type='button'
                    className='btn btn-link btn-xs text-neutral p-0 text-right'
                    onClick={() => setShowPassword(prev => !prev)}
                  >
                    {showPassword ? 'Hide password' : 'Show password'}
                  </button>
                </span>
              </div>
            </label>

            <button
              type='submit'
              className='btn btn-primary w-full'
              disabled={
                password.trim() === '' ||
                passwordConfirm.trim() === '' ||
                !passwordFitsRules ||
                passwordConfirm !== password ||
                isLoading
              }
            >
              {isLoading ? <span className='loading loading-dots loading-md'></span> : 'Reset'}
            </button>
          </form>
        </div>
      )}
    </Card>
  );
};

export default ResetPassword;
