import { getAxiosInstance } from '@Utils/apiHelpers';
import { getJwtFromLocalStorage } from '@Utils/localStorageHelpers';
import { API_URL } from '@Utils/constants';

const api = getAxiosInstance(API_URL);

export const register = async (name, email, password) => {
  const res = await api.post('/auth/register', { name, email, password });

  return res.data;
};

export const sendVerificationEmail = async email => {
  const res = await api.post('/auth/send/email-verification', { email });

  return res.data;
};

export const verifyEmail = async hash => {
  const res = await api.get('/auth/verify/email', { params: { hash } });

  return res.data;
};

export const sendResetPasswordEmail = async email => {
  const res = await api.post('/auth/send/password-reset', { email });

  return res.data;
};

export const resetPassword = async (password, hash) => {
  const res = await api.post('/auth/password/reset', { password, hash });

  return res.data;
};

export const login = async (email, password) => {
  const res = await api.post('/auth/login', { email, password });

  return res.data;
};

export const getAuthenticatedUser = async jwt => {
  const res = await api.get('/auth/me', { headers: { Authorization: `Bearer ${jwt}` } });

  return res.data;
};

export const updateUser = async (userId, updatedData) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.patch(`/users/${userId}`, updatedData, { headers: { Authorization: `Bearer ${jwt}` } });

  return res.data;
};
