import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import { verifyEmail, sendVerificationEmail } from '@Contexts/auth/AuthActions';

import { SUPPORT_EMAIL } from '@Utils/constants';

import { toast } from 'react-toastify';

import Card from '@Components/ui/Card';
import Loader from '@Components/ui/Loader';

const VerifyEmail = () => {
  const { user, dispatch } = useContext(AuthContext);

  const [status, setStatus] = useState('loading');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (status !== 'loading') return;

    const hash = searchParams.get('hash');

    if (hash) {
      setStatus('verifying');

      const activateAccount = async () => {
        try {
          const newUser = await verifyEmail(hash);

          dispatch({ type: 'userUpdated', payload: newUser });

          toast.success('Account successfully verified! You are now logged in.');

          navigate('/dashboard');
        } catch (err) {
          setStatus('failed');
        }
      };

      activateAccount();
    } else if (user) {
      if (!user.emailVerified) {
        setStatus('unverified');
      } else {
        navigate('/dashboard'); //? User is already verified.
      }
    } else {
      navigate('/auth/login'); //? User is not logged in and no hash is provided.
    }
  }, [searchParams, navigate, user, dispatch, status]);

  const handleSendVerificationEmail = async () => {
    if (!user?.email) return;

    setStatus('sending');

    try {
      await sendVerificationEmail(user.email);

      setStatus('sent');
    } catch (err) {
      setStatus('unverified');
    }
  };

  let customLoadingText = '';

  switch (status) {
    case 'sending':
      customLoadingText = 'Sending verification email';
      break;
    case 'verifying':
      customLoadingText = 'Activating account, please wait';
      break;
    default:
      break;
  }

  return (
    <Card>
      {!['unverified', 'sent', 'failed'].includes(status) && <Loader customText={customLoadingText} />}

      {status === 'unverified' && (
        <div className='text-center'>
          <h1 className='text-2xl mb-3'>Verify Email</h1>
          <p className='mb-3'>
            You should have received an email to confirm your account after the registration process.
          </p>
          <p className='mb-3'>
            If you haven't received the email, or if the link in the email has expired, please click the button below to
            send a new verification email to:
            <br />
            <b>{user.email}</b>.
          </p>
          <button type='button' className='btn btn-outline btn-primary' onClick={handleSendVerificationEmail}>
            Send Verification Email
          </button>
        </div>
      )}

      {status === 'sent' && (
        <div className='text-center'>
          <h1 className='text-2xl mb-3'>Email Sent</h1>
          <p className='mb-3'>
            We have sent a new verification email to: <b>{user.email}</b>.
          </p>
          <p>Please check your inbox and click the link in the email to verify your account.</p>
        </div>
      )}

      {status === 'failed' && (
        <div className='text-center'>
          <h1 className='text-2xl mb-3'>Verification Error</h1>
          <p className='mb-3'>An error occurred while verifying your account.</p>
          <p className='mb-3'>
            Please try sending a new verification email by logging in and clicking 'Send Verification Email'.
          </p>
          <p className='mb-3'>
            If the problem persists, please contact our support team at{' '}
            <a className='text-primary' href={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </a>
          </p>

          <Link className='btn btn-outline btn-primary' to='/auth/login'>
            Login
          </Link>
        </div>
      )}
    </Card>
  );
};

export default VerifyEmail;
