import { useState, useContext } from 'react';

import AuthContext from '@Contexts/auth/AuthContext';
import { updateUser } from '@Contexts/auth/AuthActions';

import { PASSWORD_REGEX } from '@Utils/constants';

import { toast } from 'react-toastify';

import Card from '@Components/ui/Card';

const Account = () => {
  const [formData, setFormData] = useState({ currentPassword: '', password: '', passwordConfirm: '' });
  const [passwordFitsRules, setPasswordFitsRules] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user, dispatch } = useContext(AuthContext);

  const handleFormChange = ({ target }) => {
    if (target.id === 'password') {
      setPasswordFitsRules(PASSWORD_REGEX.test(target.value));
    }

    setFormData(prev => ({ ...prev, [target.id]: target.value }));
  };

  const handlePasswordFormSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedPasswordData = { password: formData.password, previousPassword: formData.currentPassword };
      const updatedUser = await updateUser(user.id, updatedPasswordData);

      setIsLoading(false);

      toast.success('Password successfully changed.');

      dispatch({ type: 'userUpdated', payload: updatedUser });
    } catch (err) {
      //? No need to display error notification since it is handled in interceptor.
    } finally {
      setFormData({ currentPassword: '', password: '', passwordConfirm: '' });
      setPasswordFitsRules(false);
      setShowPassword(false);
      setIsLoading(false);
    }
  };

  const { currentPassword, password, passwordConfirm } = formData;
  return (
    <>
      <Card title='Account Details' className='mb-6' customWidthClasses='w-11/12'>
        <div className='w-full flex flex-col sm:flex-row flex-wrap items-center justify-center'>
          <div className='w-full sm:w-1/3 text-center'>
            <h3 className='font-bold'>Name</h3>
            <p>{user.name}</p>
          </div>
          <div className='w-full sm:w-1/3 text-center mt-3 sm:mt-0'>
            <h3 className='font-bold'>Created On</h3>
            <p>{new Date(user.createdDate).toLocaleDateString()}</p>
          </div>
          <div className='w-full sm:w-1/3 text-center mt-3 sm:mt-0'>
            <h3 className='font-bold'>Role</h3>
            <p>{user.permGroup}</p>
          </div>
          <div className='w-full sm:w-1/3 text-center mt-3'>
            <h3 className='font-bold'>Email</h3>
            <p className='text-center'>{user.email}</p>
          </div>
        </div>
      </Card>

      <Card title='Password Change'>
        <form className='w-full' onSubmit={handlePasswordFormSubmit}>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='Current Password'
            id='currentPassword'
            className={`input input-bordered w-full mb-3`}
            value={currentPassword}
            onChange={handleFormChange}
          />

          <label className='form-control'>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='New Password'
              id='password'
              className={`input input-bordered w-full ${password?.length > 0 && !passwordFitsRules ? '' : 'mb-3'}`}
              value={password}
              onChange={handleFormChange}
            />

            {password?.length > 0 && !passwordFitsRules && (
              <div className='label'>
                <span className='label-text-alt text-neutral text-left'>
                  <span>Password must match the following criteria:</span>
                  <ul className='mt-1'>
                    <li>- At least 1 lower case letter</li>
                    <li>- At least 1 upper case letter</li>
                    <li>- At least 1 number</li>
                    <li>- At least 1 special character</li>
                    <li>- At least 8 characters long</li>
                  </ul>
                </span>
              </div>
            )}
          </label>

          <label className='form-control'>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Confirm New Password'
              id='passwordConfirm'
              className={`input input-bordered w-full`}
              value={passwordConfirm}
              onChange={handleFormChange}
            />

            <div className='label'>
              <span className='label-text-alt text-neutral'>
                {passwordConfirm?.length > 0 && passwordConfirm !== password
                  ? 'Password and Confirm password must match!'
                  : ''}
              </span>

              <span className='label-text-alt'>
                <button
                  type='button'
                  className='btn btn-link btn-xs text-neutral p-0 text-right'
                  onClick={() => setShowPassword(prev => !prev)}
                >
                  {showPassword ? 'Hide password' : 'Show password'}
                </button>
              </span>
            </div>
          </label>

          <button
            type='submit'
            className='btn btn-primary w-full'
            disabled={
              currentPassword.trim() === '' ||
              password.trim() === '' ||
              passwordConfirm.trim() === '' ||
              !passwordFitsRules ||
              passwordConfirm !== password ||
              isLoading
            }
          >
            {isLoading ? <span className='loading loading-dots loading-md'></span> : 'Reset'}
          </button>
        </form>
      </Card>
    </>
  );
};

export default Account;
