import { useContext, useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import DashboardContext from '@Contexts/dashboard/DashboardContext';
import { getAuthenticatedUser } from '@Contexts/auth/AuthActions';

import useAuthStatus from '@Hooks/useAuthStatus';

import { saveJwtToLocalStorage } from '@Utils/localStorageHelpers';

import { toast } from 'react-toastify';

import Loader from '@Components/ui/Loader';

const PrivateRoute = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { dispatch: dashboardDispatch } = useContext(DashboardContext);

  const [loading, setLoading] = useState(true);

  const authStatus = useAuthStatus();

  useEffect(() => {
    if (!authStatus) return;

    if (authStatus.loggedIn && !user) {
      const getAuthUser = async () => {
        try {
          const fetchedUser = await getAuthenticatedUser(authStatus.jwt);

          dispatch({ type: 'userUpdated', payload: fetchedUser });
        } catch (err) {
          saveJwtToLocalStorage('');
          dispatch({ type: 'logoutDone' });
        } finally {
          setLoading(false);
        }
      };

      getAuthUser();
    } else if (!authStatus.loggedIn && (user || authStatus.jwt)) {
      dashboardDispatch({ type: 'reset' });

      toast.warn('Your session has expired. Please login again.');

      setLoading(false);
    } else {
      if (!authStatus.loggedIn && !authStatus.jwt) {
        if (authStatus.loggedOut) {
          dashboardDispatch({ type: 'reset' });

          toast.success('Successfully logged out.');
        } else {
          toast.warn('You must be logged in to access this page.');
        }
      }

      setLoading(false);
    }
  }, [authStatus, user, dispatch]);

  if (loading) {
    return (
      <div className='h-screen w-screen'>
        <Loader />
      </div>
    );
  }

  return authStatus?.loggedIn && user ? <Outlet /> : <Navigate to='/auth/login' />;
};

export default PrivateRoute;
