import { useState } from 'react';

import { toast } from 'react-toastify';

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ cancelPayment = () => {}, returnUrl }) => {
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleFormSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl
      }
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      if (['card_declined'].includes(error.code)) {
        toast.warn(`${error.message} Please try again or try with a different card.`);
      } else if (['expired_card'].includes(error.code)) {
        toast.warn(`${error.message} Please try with a different card.`);
      } else {
        toast.warn(error.message);
      }
    } else {
      toast.error('An unexpected error occurred. Please try again.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs'
  };

  return (
    <form className='w-full flex flex-col items-center' onSubmit={handleFormSubmit}>
      <PaymentElement className='w-full' options={paymentElementOptions} />

      {stripe && elements && (
        <div className='w-full mt-6 flex flex-col items-center justify-center sm:flex-row sm:justify-around'>
          <button
            type='button'
            className='btn btn-error btn-outline w-full mb-3 sm:mb-0 sm:w-1/3'
            onClick={() => cancelPayment()}
          >
            Cancel
          </button>

          <button type='submit' className='btn btn-primary w-full sm:w-1/3' disabled={isLoading}>
            {isLoading ? <span className='loading loading-dots loading-xs mx-2'></span> : 'Pay now'}
          </button>
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
