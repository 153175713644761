import { Outlet } from 'react-router-dom';

import { AUTH_BG_GRADIENT_CLASSES } from '@Utils/constants';

//* UI Components
import Navbar from '@Components/ui/Navbar';
import HeroSection from '@Components/ui/HeroSection';

const AuthLayout = () => {
  return (
    <>
      <Navbar onlyHomeLink />
      <main>
        <HeroSection fullHeight hasNavbar className={AUTH_BG_GRADIENT_CLASSES}>
          <Outlet />
        </HeroSection>
      </main>
    </>
  );
};

export default AuthLayout;
