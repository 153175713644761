const Loader = ({ customText }) => {
  return (
    <div className='w-full h-full flex flex-col items-center justify-center'>
      <h1 className='text-lg'>{customText || 'Retrieving information'}</h1>
      <span className='loading loading-dots loading-lg text-primary'></span>
    </div>
  );
};

export default Loader;
