import HeroSection from '@Components/ui/HeroSection';

const Pricing = () => {
  return (
    <HeroSection>
      <div className='max-w-md'>
        <h1 className='text-5xl font-bold'>Pricing</h1>
        <p className='py-6'>
          Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi exercitationem quasi. In deleniti
          eaque aut repudiandae et a id nisi.
        </p>
        <button className='btn btn-primary'>Get Started</button>
      </div>
    </HeroSection>
  );
};

export default Pricing;
