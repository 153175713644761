import { useState, useEffect, useCallback, useContext } from 'react';

import AuthContext from '@Contexts/auth/AuthContext';

import { getJwtFromLocalStorage, saveJwtToLocalStorage } from '@Utils/localStorageHelpers';

let savedTimeout = null;

const useAuthStatus = () => {
  const { logout, preventSessionTimeout, dispatch } = useContext(AuthContext);
  const [authStatus, setAuthStatus] = useState(null);

  const clearPreviousTimeout = useCallback(() => {
    if (savedTimeout) {
      clearTimeout(savedTimeout);
      savedTimeout = null;
    }
  }, []);

  const checkTokenOnExpiration = useCallback(
    setAuthStatus => {
      const jwt = getJwtFromLocalStorage();

      //? Clear the previous timeout.
      clearPreviousTimeout();

      //? If token is present and valid, schedule another check.
      if (jwt && isTokenValid(jwt)) {
        const decodedJwt = JSON.parse(atob(jwt.split('.')[1]));
        const delayBeforeExpiration = decodedJwt.exp * 1000 - Date.now() - 10; //? -1 to avoid requests sent with an expired token.

        savedTimeout = setTimeout(() => {
          checkTokenOnExpiration(setAuthStatus);
        }, delayBeforeExpiration);
      } else {
        saveJwtToLocalStorage('');
        setAuthStatus({ loggedIn: false, jwt, loggedOut: false }); //? This will trigger session expired in PrivateRoute component.
        dispatch({ type: 'logoutDone' });
      }
    },
    [clearPreviousTimeout, dispatch]
  );

  useEffect(() => {
    if (preventSessionTimeout) {
      clearPreviousTimeout();
    } else if (!savedTimeout && authStatus?.loggedIn) {
      checkTokenOnExpiration(setAuthStatus);
    }
  }, [checkTokenOnExpiration, clearPreviousTimeout, preventSessionTimeout, authStatus]);

  useEffect(() => {
    if (logout) {
      clearPreviousTimeout();
      saveJwtToLocalStorage('');
      setAuthStatus({ loggedIn: false, jwt: null, loggedOut: true }); //? User has logged out.
      dispatch({ type: 'logoutDone' });
    } else {
      const jwt = getJwtFromLocalStorage();

      if (jwt && isTokenValid(jwt)) {
        checkTokenOnExpiration(setAuthStatus); //? Schedule a check for expiration.

        setAuthStatus({ loggedIn: true, jwt, loggedOut: false }); //? Token is present and valid.
      } else {
        if (jwt && !isTokenValid(jwt)) {
          saveJwtToLocalStorage('');
          setAuthStatus({ loggedIn: false, jwt, loggedOut: false }); //? Token is present but invalid / expired.
          dispatch({ type: 'logoutDone' });
        } else {
          setAuthStatus({ loggedIn: false, jwt: null, loggedOut: false }); //? Token is not present.
        }
      }
    }

    return clearPreviousTimeout;
  }, [clearPreviousTimeout, checkTokenOnExpiration, logout, dispatch]);

  return authStatus;
};

export default useAuthStatus;

//* -- Helpers --
const isTokenValid = jwt => {
  const decodedJwt = JSON.parse(atob(jwt.split('.')[1]));

  return decodedJwt?.exp * 1000 > Date.now() || false;
};
//* ----
