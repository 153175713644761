import { useOutletContext } from 'react-router-dom';

import UserProjectDetails from '@Pages/dashboard/user/UserProjectDetails';
import AdminProjectDetails from '@Pages/dashboard/admin/AdminProjectDetails';

const Project = () => {
  const [displayAdminComponents] = useOutletContext();

  return displayAdminComponents ? <AdminProjectDetails /> : <UserProjectDetails />;
};

export default Project;
