import PropTypes from 'prop-types';

const Card = ({ children, title, className, customWidthClasses, customContentClasses }) => {
  return (
    <div className={`card bg-base-200 shadow-xl ${customWidthClasses || 'w-80 sm:w-96 md:w-[450px]'} ${className}`}>
      <div className={`card-body ${customContentClasses || 'items-center'}`}>
        {title && <h1 className='card-title'>{title}</h1>}
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  customWidthClasses: PropTypes.string,
  customContentClasses: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

Card.defaultProps = {
  title: '',
  className: '',
  customWidthClasses: '',
  customContentClasses: ''
};

export default Card;
