import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import DashboardContext from '@Contexts/dashboard/DashboardContext';
import { getUserProjects, createNewProject } from '@Contexts/dashboard/DashboardActions';

import { getBadgeColor } from '@Utils/helpers';
import { MAX_SHORT_DESCRIPTION_LENGTH } from '@Utils/constants';

import { toast } from 'react-toastify';

import Loader from '@Components/ui/Loader';
import Card from '@Components/ui/Card';

const UserProjects = () => {
  const [isWaitingForCreateResponse, setIsWaitingForCreateResponse] = useState(false);
  const [formData, setFormData] = useState({ name: '', description: '' });
  const [isFetchingProjects, setIsFetchingProjects] = useState(true);
  const [displayCreateForm, setDisplayCreateForm] = useState(false);

  const { user } = useContext(AuthContext);
  const { projectsData, dispatch: dashboardDispatch } = useContext(DashboardContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectsData === null) {
      const fetchProjects = async () => {
        try {
          const fetchedProjectsData = await getUserProjects(user.id);

          dashboardDispatch({
            type: 'projectsDataUpdated',
            payload: getPayloadFromProjectsData(fetchedProjectsData, projectsData)
          });
        } catch (err) {
          dashboardDispatch({
            type: 'projectsDataUpdated',
            payload: {
              projectsPerPages: [],
              currentPage: 1,
              pages: 1,
              totalItems: 0
            }
          });
        } finally {
          setIsFetchingProjects(false);
        }
      };

      fetchProjects();
    } else {
      setIsFetchingProjects(false);
    }
  }, [projectsData, dashboardDispatch, user]);

  if (isFetchingProjects) return <Loader />;

  const handleNewProjectClick = () => {
    displayCreateForm && setFormData({ name: '', description: '' });
    setDisplayCreateForm(prev => !prev);
  };

  const handleFormChange = ({ target }) => {
    setFormData(prev => ({ ...prev, [target.id]: target.value }));
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    setIsWaitingForCreateResponse(true);

    try {
      const newProject = await createNewProject(user.id, formData.name, formData.description);
      const updatedProjectsData = await getUserProjects(user.id); //? Fetching first page of projects again to avoid pagination issues

      dashboardDispatch({
        type: 'projectsDataUpdated',
        payload: getPayloadFromProjectsData(updatedProjectsData)
      });

      toast.success('New project successfully created.');

      setDisplayCreateForm(false);
      setIsWaitingForCreateResponse(false);
      setFormData({ name: '', description: '' });

      navigate(`/dashboard/projects/${newProject.id}`);
    } catch (err) {
      setIsWaitingForCreateResponse(false);
      setFormData({ name: '', description: '' });
    }
  };

  const { name, description } = formData;

  const { projectsPerPages, currentPage, pages, totalItems } = projectsData;
  const projects = projectsPerPages?.[currentPage - 1] || [];

  const handlePageChange = async direction => {
    let pageToFetch = null;

    if (direction === 'previous' && currentPage > 1) {
      pageToFetch = currentPage - 1;
    } else if (direction === 'next' && currentPage < pages) {
      pageToFetch = currentPage + 1;
    }

    if (pageToFetch === null) return;

    setDisplayCreateForm(false);
    setFormData({ name: '', description: '' });

    if (projectsData?.projectsPerPages[pageToFetch - 1]) {
      dashboardDispatch({
        type: 'projectsDataUpdated',
        payload: {
          ...projectsData,
          currentPage: pageToFetch
        }
      });
    } else {
      setIsFetchingProjects(true);

      try {
        const updatedProjectsData = await getUserProjects(user.id, pageToFetch);

        dashboardDispatch({
          type: 'projectsDataUpdated',
          payload: getPayloadFromProjectsData(updatedProjectsData, projectsData)
        });
      } catch (err) {
        //? Do nothing, error is handled by the interceptor.
      } finally {
        setIsFetchingProjects(false);
      }
    }
  };

  const handleViewProjectClick = project => {
    dashboardDispatch({ type: 'activeProjectUpdated', payload: project });
    navigate(`${project.id}`);
  };

  return (
    <>
      <Card
        className={displayCreateForm || totalItems > 0 ? 'mb-6' : ''}
        customWidthClasses='w-11/12'
        customContentClasses='items-center sm:flex-row justify-between'
      >
        <h1 className='font-bold text-lg'>
          {totalItems > 0 ? `${totalItems} Project${totalItems > 1 ? 's' : ''}` : 'You do not have any projects yet.'}
        </h1>
        <button
          type='button'
          className={`btn ${displayCreateForm ? 'btn-outline btn-error' : 'btn-primary'}`}
          onClick={handleNewProjectClick}
        >
          {displayCreateForm ? 'Cancel' : 'New Project'}
        </button>
      </Card>

      {displayCreateForm && (
        <Card title='New Project' className={totalItems > 0 ? 'mb-6' : ''} customWidthClasses='w-11/12'>
          <form className='w-full sm:w-2/3 xl:w-1/2' onSubmit={handleFormSubmit}>
            <input
              type='text'
              placeholder='Name'
              id='name'
              className='input input-bordered w-full mb-3'
              onChange={handleFormChange}
              value={name}
            />

            <input
              type='text'
              placeholder='Description'
              id='description'
              className='input input-bordered w-full mb-3'
              onChange={handleFormChange}
              value={description}
            />

            <button
              type='submit'
              className='btn btn-primary w-full mb-3'
              disabled={Object.values(formData).indexOf('') !== -1 || isWaitingForCreateResponse}
            >
              {isWaitingForCreateResponse ? <span className='loading loading-dots loading-md'></span> : 'Create'}
            </button>
          </form>
        </Card>
      )}

      {totalItems > 0 && (
        <Card customWidthClasses='w-11/12'>
          <div className='overflow-x-auto max-w-full'>
            <table className='table table-sm sm:table-md xl:table-lg text-center'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th className='hidden md:table-cell'>Created On</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {projects.map(project => (
                  <tr key={project.id}>
                    <td>{project.name}</td>
                    <td>
                      {project.description.length >= MAX_SHORT_DESCRIPTION_LENGTH
                        ? project.description.substring(0, MAX_SHORT_DESCRIPTION_LENGTH).trim() + '...'
                        : project.description}
                    </td>
                    <td>
                      <div className={`badge badge-outline whitespace-nowrap ${getBadgeColor(project.status)}`}>
                        {project.status}
                      </div>
                    </td>
                    <td className='hidden md:table-cell whitespace-nowrap'>
                      {new Date(project.createdDate).toDateString()}
                    </td>
                    <td>
                      <button
                        type='button'
                        className='btn btn-outline btn-secondary btn-xs'
                        onClick={() => handleViewProjectClick(project)}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='join'>
            <button
              type='button'
              className='join-item btn btn-xs sm:btn-md btn-outline'
              disabled={currentPage === 1}
              onClick={() => handlePageChange('previous')}
            >
              «
            </button>
            <button type='button' className='join-item btn btn-xs sm:btn-md btn-outline'>
              Page {currentPage}
            </button>
            <button
              type='button'
              className='join-item btn btn-xs sm:btn-md btn-outline'
              disabled={currentPage === pages}
              onClick={() => handlePageChange('next')}
            >
              »
            </button>
          </div>
        </Card>
      )}
    </>
  );
};

//* -- Helpers --
const getPayloadFromProjectsData = (newProjectsData, projectsData) => {
  const projectsPerPages = !projectsData
    ? []
    : Array.isArray(projectsData?.projectsPerPages)
    ? [...projectsData.projectsPerPages]
    : [];

  projectsPerPages[newProjectsData.currentPage - 1] = newProjectsData.projects;

  delete newProjectsData.projects;

  return {
    projectsPerPages,
    ...newProjectsData
  };
};
//* ----

export default UserProjects;
