import { createContext, useReducer } from 'react';

import dashboardReducer from './DashboardReducer';

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const initialState = {
    projectsData: null,
    activeProject: null,
    activeProjectFilesData: null,
    activeProjectPaymentData: null
  };

  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  return (
    <DashboardContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext as default, DashboardProvider };
