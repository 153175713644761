import { getAxiosInstance } from '@Utils/apiHelpers';
import { getJwtFromLocalStorage } from '@Utils/localStorageHelpers';
import { API_URL } from '@Utils/constants';

// TODO: Refactor and use interceptors to add Authorization header.

const api = getAxiosInstance(API_URL);

export const getUserProjects = async (userId, page = 1, limit = 10) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.get(`/users/${userId}/projects`, {
    headers: { Authorization: `Bearer ${jwt}` },
    params: { page, limit }
  });

  return res.data;
};

export const createNewProject = async (userId, name, description) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.post(
    `/users/${userId}/projects`,
    { name, description },
    { headers: { Authorization: `Bearer ${jwt}` } }
  );

  return res.data;
};

export const getUserProject = async (userId, projectId) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.get(`/users/${userId}/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const updateUserProject = async (userId, projectId) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.patch(
    `/users/${userId}/projects/${projectId}`,
    {},
    {
      headers: { Authorization: `Bearer ${jwt}` }
    }
  );

  return res.data;
};

export const deleteUserProject = async (userId, projectId) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.delete(`/users/${userId}/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const getUserProjectFiles = async (userId, projectId, page = 1, limit = 10) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.get(`/users/${userId}/projects/${projectId}/files`, {
    headers: { Authorization: `Bearer ${jwt}` },
    params: { page, limit }
  });

  return res.data;
};

export const uploadUserProjectFile = async (userId, projectId, formData, onProgressCb = null) => {
  const jwt = getJwtFromLocalStorage();

  const config = { headers: { Authorization: `Bearer ${jwt}`, 'Content-Type': 'multipart/form-data' } };

  if (onProgressCb) config.onUploadProgress = onProgressCb;

  const res = await api.post(`/users/${userId}/projects/${projectId}/files`, formData, config);

  return res.data;
};

export const downloadUserProjectFile = async (userId, projectId, fileId, onProgressCb = null) => {
  const jwt = getJwtFromLocalStorage();

  const config = { headers: { Authorization: `Bearer ${jwt}` }, responseType: 'blob' };

  if (onProgressCb) config.onDownloadProgress = onProgressCb;

  const res = await api.get(`/users/${userId}/projects/${projectId}/files/${fileId}`, config);

  return res;
};

export const deleteUserProjectFile = async (userId, projectId, fileId) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.delete(`/users/${userId}/projects/${projectId}/files/${fileId}`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const createUserProjectPayment = async (userId, projectId) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.post(
    `/users/${userId}/projects/${projectId}/payment`,
    {},
    {
      headers: { Authorization: `Bearer ${jwt}` }
    }
  );

  return res.data;
};

export const getUserProjectPayment = async (userId, projectId) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.get(`/users/${userId}/projects/${projectId}/payment`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const cancelUserProjectPayment = async (userId, projectId) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.delete(`/users/${userId}/projects/${projectId}/payment`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

//* -- Admin Actions --
export const getProjects = async (
  page = 1,
  limit = 10,
  sort = 'updatedDate',
  orderBy = 'desc',
  searchFields,
  search
) => {
  const jwt = getJwtFromLocalStorage();

  const params = { page, limit, sort, orderBy };

  if (searchFields) params.searchFields = Array.isArray(searchFields) ? searchFields.join(',') : searchFields;

  if (search) params.search = search;

  const res = await api.get(`/internal/projects`, {
    headers: { Authorization: `Bearer ${jwt}` },
    params
  });

  return res.data;
};

export const getProject = async projectId => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.get(`/internal/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const updateProject = async (projectId, data = {}) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.patch(`/internal/projects/${projectId}`, data, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const deleteProject = async projectId => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.delete(`/internal/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const getProjectFiles = async (userId, projectId, page = 1, limit = 10) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.get(`/internal/users/${userId}/projects/${projectId}/files`, {
    headers: { Authorization: `Bearer ${jwt}` },
    params: { page, limit }
  });

  return res.data;
};

export const getProjectPayment = async projectId => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.get(`/internal/projects/${projectId}/payment`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const refundProjectPayment = async projectId => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.patch(
    `/internal/projects/${projectId}/payment`,
    {},
    {
      headers: { Authorization: `Bearer ${jwt}` }
    }
  );

  return res.data;
};

export const downloadFile = async (fileId, onProgressCb = null) => {
  const jwt = getJwtFromLocalStorage();
  const config = { headers: { Authorization: `Bearer ${jwt}` }, responseType: 'blob' };

  if (onProgressCb) config.onDownloadProgress = onProgressCb;

  const res = await api.get(`/internal/files/${fileId}`, config);

  return res;
};

export const updateFile = async (fileId, data = {}) => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.patch(`/internal/files/${fileId}`, data, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const deleteFile = async fileId => {
  const jwt = getJwtFromLocalStorage();
  const res = await api.delete(`/internal/files/${fileId}`, {
    headers: { Authorization: `Bearer ${jwt}` }
  });

  return res.data;
};

export const uploadProjectFile = async (userId, projectId, formData, onProgressCb = null) => {
  const jwt = getJwtFromLocalStorage();

  const config = { headers: { Authorization: `Bearer ${jwt}`, 'Content-Type': 'multipart/form-data' } };

  if (onProgressCb) config.onUploadProgress = onProgressCb;

  const res = await api.post(`/internal/users/${userId}/projects/${projectId}/files`, formData, config);

  return res.data;
};
//* ----
