import PropTypes from 'prop-types';

const HeroSection = ({ children, fullHeight, hasNavbar, className }) => {
  return (
    <section className={`hero ${fullHeight ? hasNavbar ? 'h-[calc(100vh-4rem)]' : 'min-h-screen' : ''} ${className}`}>
      <div className='hero-content'>{children}</div>
    </section>
  );
};

HeroSection.propTypes = {
  fullHeight: PropTypes.bool,
  hasNavbar: PropTypes.bool,
  className: PropTypes.string
};

HeroSection.defaultProps = {
  fullHeight: false,
  hasNavbar: true,
  className: ''
};

export default HeroSection;
