import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

import { saveJwtToLocalStorage } from '@Utils/localStorageHelpers';
import { STRIPE_API_KEY } from '@Utils/constants';

import { toast } from 'react-toastify';

const axiomInstanceMap = new Map();

export const getAxiosInstance = (url, responseInterceptorFuncs = getDefaultResponseInterceptor()) => {
  if (axiomInstanceMap.has(url)) return axiomInstanceMap.get(url);

  const instance = axios.create({ baseURL: url });

  if (Array.isArray(responseInterceptorFuncs) && responseInterceptorFuncs.length === 2)
    instance.interceptors.response.use(...responseInterceptorFuncs);

  axiomInstanceMap.set(url, instance);

  return instance;
};

const getDefaultResponseInterceptor = () => {
  const successHandler = res => {
    const resData = res.data;
    const token = resData?.token;

    if (token) saveJwtToLocalStorage(token);

    return resData;
  };

  const errorHandler = err => {
    const res = err.response;
    const resData = res?.data;
    const token = resData?.token;

    if (token) saveJwtToLocalStorage(token);

    if (resData?.statusCode === 500) {
      toast.error('Server error! Please contact our support team if the problem persists.');
    } else {
      toast.warn(resData?.error?.message || 'Something went wrong!');
    }

    return Promise.reject(resData);
  };

  return [successHandler, errorHandler];
};

let _stripePromise;

export const getStripePromise = () => {
  if (_stripePromise) return _stripePromise;

  _stripePromise = loadStripe(STRIPE_API_KEY);

  return _stripePromise;
};
