import { PROJECT_STATUSES, FILE_STATUSES, PAYMENT_STATUSES } from '@Utils/constants';

export const getBadgeColor = status => {
  return `badge-${getColor(status)}`;
};

export const getColor = status => {
  switch (status) {
    case PROJECT_STATUSES.paid:
    case PROJECT_STATUSES.completed:
    case FILE_STATUSES.completed:
    case PAYMENT_STATUSES.succeeded:
      return 'success';
    case PROJECT_STATUSES.open:
    case PROJECT_STATUSES.pending:
    case PROJECT_STATUSES.processing:
    case FILE_STATUSES.processing:
    case FILE_STATUSES.received:
    case PAYMENT_STATUSES.pending:
    case PAYMENT_STATUSES.processing:
      return 'info';
    case PROJECT_STATUSES.cancelled:
    case PAYMENT_STATUSES.failed:
    case PAYMENT_STATUSES.refunded:
      return 'warning';
    default:
      return '';
  }
};
