import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { AuthProvider } from '@Contexts/auth/AuthContext';
import { DashboardProvider } from '@Contexts/dashboard/DashboardContext';

import MainLayout from '@Components/layouts/MainLayout';
import AuthLayout from '@Components/layouts/AuthLayout';
import DashboardLayout from '@Components/layouts/DashboardLayout';

import Home from '@Pages/Home';
import AboutUs from '@Pages/AboutUs';
import Pricing from '@Pages/Pricing';
import Technology from '@Pages/Technology';

import Login from '@Pages/auth/Login';
import Register from '@Pages/auth/Register';
import VerifyEmail from '@Pages/auth/VerifyEmail';
import ResetPassword from '@Pages/auth/ResetPassword';

import Summary from '@Pages/dashboard/Summary';
import Projects from '@Pages/dashboard/Projects';
import ProjectDetails from '@Pages/dashboard/ProjectDetails';
import Account from '@Pages/dashboard/Account';

import NotFound from '@Pages/NotFound';

import PrivateRoute from '@Components/security/PrivateRoute';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  return (
    <AuthProvider>
      <DashboardProvider>
        <ToastContainer />
        <Router>
          <Routes>
            {/* Private routes */}
            <Route element={<PrivateRoute />}>
              <Route path='dashboard' element={<DashboardLayout />}>
                <Route path='' element={<Summary />} />
                <Route path='projects' element={<Projects />} />
                <Route path='projects/:projectId' element={<ProjectDetails />} />
                <Route path='account' element={<Account />} />
                <Route path='*' element={<NotFound />} />
              </Route>
            </Route>

            {/* Public routes */}
            <Route path='auth' element={<AuthLayout />}>
              <Route path='' element={<Navigate to='/auth/login' />} />
              <Route path='login' element={<Login />} />
              <Route path='register' element={<Register />} />
              <Route path='verify/email' element={<VerifyEmail />} />
              <Route path='reset-password' element={<ResetPassword />} />
            </Route>

            <Route path='/' element={<MainLayout />}>
              <Route path='' element={<Home />} />
              <Route path='technology' element={<Technology />} />
              <Route path='about-us' element={<AboutUs />} />
              <Route path='pricing' element={<Pricing />} />

              {/* 404 */}
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </DashboardProvider>
    </AuthProvider>
  );
};

export default App;
