import { useOutletContext, Navigate } from 'react-router-dom';

import AdminSummary from '@Pages/dashboard/admin/AdminSummary';

const Summary = () => {
  const [displayAdminComponents] = useOutletContext();

  return displayAdminComponents ? <AdminSummary /> : <Navigate to='/dashboard/projects' />;
};

export default Summary;
