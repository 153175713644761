const authReducer = (state, action) => {
  switch (action.type) {
    case 'userUpdated':
      return { ...state, user: action.payload };
    case 'preventSessionTimeoutUpdated':
      return { ...state, preventSessionTimeout: action.payload };
    case 'logout':
      return { ...state, logout: true };
    case 'logoutDone':
      return { user: null, logout: false, preventSessionTimeout: false };
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};

export default authReducer;
