import { Link } from 'react-router-dom';

import HeroSection from '@Components/ui/HeroSection';

import introductionImg from '@Assets/png/artecIntro.png';

const Home = () => {
  return (
    <>
      {/* Introduction Section */}
      <HeroSection className='mb-4'>
        <div className='flex flex-col gap-y-4 items-center md:flex-row md:justify-evenly'>
          <div className='w-4/5 md:w-2/5'>
            <img className='mx-auto' src={introductionImg} />
          </div>

          <div className='w-full text-center md:w-1/3 md:text-left'>
            <h1 className='text-3xl font-bold'>Introduction</h1>
            <p className='py-6'>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti dolorem dignissimos sapiente nemo ut
              aliquam fugit aspernatur corporis asperiores illum, ex quaerat laudantium! Magni ipsum amet obcaecati
              recusandae numquam repellat.
            </p>

            <Link className='btn btn-primary' to='/auth/register'>
              Try Now
            </Link>
          </div>
        </div>
      </HeroSection>

      {/* Technology Section */}
      <HeroSection className='mb-4'>
        <div className='flex flex-col items-center'>
          <div className='text-center w-full md:w-3/4'>
            <h1 className='text-3xl font-bold'>Technology</h1>

            <p className='py-6'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, distinctio. Nemo, temporibus, aut
              facere vero quisquam eum, labore autem aperiam exercitationem est officia. Hic, aut? Ex quia vel ducimus
              mollitia.
            </p>

            <Link className='btn btn-primary btn-outline normal-case' to='/technology'>
              Learn More
            </Link>
          </div>
        </div>
      </HeroSection>

      {/* About Us Section */}
      <HeroSection>
        <div className='flex flex-col items-center'>
          <div className='text-center w-full md:w-3/4'>
            <h1 className='text-3xl font-bold'>About Us</h1>

            <p className='py-6'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, distinctio. Nemo, temporibus, aut
              facere vero quisquam eum, labore autem aperiam exercitationem est officia. Hic, aut? Ex quia vel ducimus
              mollitia.{' '}
              {
                <Link className='link' to='/about-us'>
                  More about us...
                </Link>
              }
            </p>
          </div>
        </div>
      </HeroSection>
    </>
  );
};

export default Home;
