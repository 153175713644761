import { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import DashboardContext from '@Contexts/dashboard/DashboardContext';
import {
  getUserProject,
  updateUserProject,
  deleteUserProject,
  getUserProjectFiles,
  uploadUserProjectFile,
  downloadUserProjectFile,
  deleteUserProjectFile,
  createUserProjectPayment,
  getUserProjectPayment
} from '@Contexts/dashboard/DashboardActions';

import usePageVisibility from '@Hooks/usePageVisibility';

import { getBadgeColor } from '@Utils/helpers';
import {
  PROJECT_STATUSES,
  MAX_PROJECT_FILES,
  PROJECT_STATUSES_PREVENT_CHANGES,
  PAYMENT_STATUSES
} from '@Utils/constants';

import { toast } from 'react-toastify';

import ProjectCheckout from '@Components/stripe/ProjectCheckout';
import Loader from '@Components/ui/Loader';
import Card from '@Components/ui/Card';

import { FaCloudDownloadAlt, FaRegTrashAlt } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';

const POLLING_INTERVAL = 30000;

const UserProjectDetails = () => {
  const { user, dispatch: authDispatch } = useContext(AuthContext);
  const {
    activeProject,
    activeProjectFilesData,
    activeProjectPaymentData,
    dispatch: dashboardDispatch
  } = useContext(DashboardContext);

  const isPageVisible = usePageVisibility();

  const [formData, setFormData] = useState({ nodes: 0, focusAreas: '', file: null });
  const [isFetchingProject, setIsFetchingProject] = useState(true);
  const [isDeletingProject, setIsDeletingProject] = useState(false);
  const [isPollingEnabled, setIsPollingEnabled] = useState(true);
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);
  const [rndFileKey, setRndFileKey] = useState(Math.random()); //? This is used to reset the file input after a file is uploaded.
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [fileToDownload, setFileToDownload] = useState(null);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [allowCheckout, setAllowCheckout] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const deleteProjectModalRef = useRef();
  const downloadFileModalRef = useRef();
  const deleteFileModalRef = useRef();
  const checkoutModalRef = useRef();
  const loadingModalRef = useRef();
  const timerIdRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeProject === null || activeProject.id !== projectId) {
      const fetchProject = async () => {
        try {
          const fetchedProject = await getUserProject(user.id, projectId);

          dashboardDispatch({ type: 'activeProjectUpdated', payload: fetchedProject });
        } catch (err) {
          //? Error is handled by the interceptor.
          navigate('/dashboard/projects');
        } finally {
          setIsFetchingProject(false);
        }
      };

      fetchProject();
    } else if (activeProjectFilesData === null || activeProjectFilesData.projectId !== projectId) {
      setIsFetchingProject(false); //? This is needed because activeProject is already set (either fetched or passed).

      const fetchFiles = async () => {
        try {
          const fetchedFilesData = await getUserProjectFiles(user.id, projectId, undefined, MAX_PROJECT_FILES);

          fetchedFilesData.projectId = projectId;

          dashboardDispatch({
            type: 'activeProjectFilesDataUpdated',
            payload: fetchedFilesData
          });
        } catch (err) {
          dashboardDispatch({
            type: 'activeProjectFilesDataUpdated',
            payload: {
              files: [],
              currentPage: 1,
              pages: 1,
              totalItems: 0,
              projectId: projectId
            }
          });
        } finally {
          setIsFetchingFiles(false);
        }
      };

      fetchFiles();
    } else {
      setIsFetchingProject(false);
      setIsFetchingFiles(false);
    }
  }, [activeProject, projectId, dashboardDispatch, user, activeProjectFilesData, navigate]);

  //? This is used to poll for changes in the payment status of the project.
  useEffect(() => {
    if (
      !user.id ||
      !projectId ||
      activeProject === null ||
      activeProject.id !== projectId ||
      activeProject.status !== PROJECT_STATUSES.pending ||
      (activeProjectPaymentData !== null &&
        activeProjectPaymentData.projectId === projectId &&
        ![PAYMENT_STATUSES.pending, PAYMENT_STATUSES.processing].includes(activeProjectPaymentData?.status))
    )
      return;

    const pollingCallback = async () => {
      try {
        const fetchedProjectPaymentData = await getUserProjectPayment(user.id, projectId);

        if (
          activeProjectPaymentData === null ||
          activeProjectPaymentData.projectId !== projectId ||
          activeProjectPaymentData.status !== fetchedProjectPaymentData.status
        ) {
          if (fetchedProjectPaymentData.status === PAYMENT_STATUSES.succeeded) {
            const fetchProject = async () => {
              try {
                const fetchedProject = await getUserProject(user.id, projectId);

                dashboardDispatch({ type: 'activeProjectUpdated', payload: fetchedProject });
                dashboardDispatch({ type: 'activeProjectPaymentDataUpdated', payload: fetchedProjectPaymentData });

                toast.success('Payment successfully processed.');
              } catch (err) {
                //? Error is handled by the interceptor.
              }
            };

            fetchProject();
          } else {
            toast.info('Payment status updated.');

            dashboardDispatch({ type: 'activeProjectPaymentDataUpdated', payload: fetchedProjectPaymentData });
          }
        }
      } catch (err) {
        setIsPollingEnabled(false);
        toast.warn('Failed to retrieve payment data. Stopped polling.');
      }
    };

    const startPolling = () => {
      pollingCallback(); //? Call it once to avoid waiting for the first interval.

      timerIdRef.current = setInterval(pollingCallback, POLLING_INTERVAL);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => stopPolling();
  }, [isPageVisible, isPollingEnabled, activeProject, activeProjectPaymentData, projectId, user.id, dashboardDispatch]);

  //? This is used to handle the redirect from the payment page and display a success message.
  useEffect(() => {
    const redirectStatus = searchParams.get('redirect_status');
    const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');

    if (redirectStatus === 'succeeded' && paymentIntentClientSecret) {
      setSearchParams({});
      toast.success('Payment successfully processed.');
    }
  }, [searchParams, setSearchParams]);

  if (isFetchingProject) return <Loader customText='Retrieving project' />;

  const handleDeleteProjectClick = () => {
    if (PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)) return;

    deleteProjectModalRef?.current?.showModal();
  };

  const handleCloseCheckoutModal = () => {
    setIsCheckingOut(false);
    checkoutModalRef?.current?.close?.();
    setAllowCheckout(false);
  };

  const checkoutProject = async () => {
    if (![PROJECT_STATUSES.open, PROJECT_STATUSES.pending].includes(activeProject.status)) return;

    setIsCheckingOut(true);
    checkoutModalRef?.current?.showModal?.();

    if (activeProject.status === PROJECT_STATUSES.pending) {
      setAllowCheckout(true); //? Payment object has already been created, so we can allow the user to resume the checkout.
      return;
    }

    if (activeProject.currentPriceInCents > 0) {
      try {
        const projectPaymentData = await createUserProjectPayment(user.id, projectId);
        const updatedActiveProject = await getUserProject(user.id, projectId);

        dashboardDispatch({ type: 'activeProjectPaymentDataUpdated', payload: projectPaymentData });
        dashboardDispatch({ type: 'activeProjectUpdated', payload: updatedActiveProject });
        dashboardDispatch({ type: 'projectsDataUpdated', payload: null }); //? Forces a refetch of the projects data.

        setAllowCheckout(true);
      } catch (err) {
        handleCloseCheckoutModal();
      }
    } else {
      try {
        const updatedActiveProject = await updateUserProject(user.id, projectId);

        dashboardDispatch({ type: 'activeProjectUpdated', payload: updatedActiveProject });
        dashboardDispatch({ type: 'projectsDataUpdated', payload: null }); //? Forces a refetch of the projects data.
      } catch (err) {
        //? Do nothing, error is handled by the interceptor.
      } finally {
        handleCloseCheckoutModal();
      }
    }
  };

  const deleteProject = async () => {
    if (PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)) return;

    setIsDeletingProject(true);

    try {
      await deleteUserProject(user.id, projectId);

      dashboardDispatch({ type: 'projectsDataUpdated', payload: null }); //? Forces a refetch of the projects data.

      toast.success('Project successfully deleted.');

      navigate('/dashboard/projects');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      deleteProjectModalRef?.current?.close?.();
      setIsDeletingProject(false);
    }
  };

  const handleFormChange = ({ target }) => {
    if (target.id === 'file') {
      setFormData(prev => ({ ...prev, file: target.files[0] }));
    } else {
      setFormData(prev => ({ ...prev, [target.id]: target.value }));
    }
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    if (activeProject.status !== PROJECT_STATUSES.open) return;

    authDispatch({ type: 'preventSessionTimeoutUpdated', payload: true });
    setIsUploading(true);

    loadingModalRef?.current?.showModal();

    const actualFormData = new FormData();

    Object.keys(formData).forEach(key => {
      actualFormData.append(key, formData[key]);
    });

    const onProgressCb = progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      setUploadProgress(percentCompleted);
    };

    try {
      const uploadedFile = await uploadUserProjectFile(user.id, projectId, actualFormData, onProgressCb);
      const fetchedProject = await getUserProject(user.id, projectId);

      const updatedActiveProjectFilesData = {
        ...activeProjectFilesData,
        files: [uploadedFile, ...activeProjectFilesData.files],
        totalItems: activeProjectFilesData.totalItems + 1
      };

      dashboardDispatch({ type: 'activeProjectFilesDataUpdated', payload: updatedActiveProjectFilesData });
      dashboardDispatch({ type: 'activeProjectUpdated', payload: fetchedProject }); //? Update project with new data

      toast.success('File successfully uploaded.');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      setFormData({ nodes: 0, focusAreas: '', file: null });
      authDispatch({ type: 'preventSessionTimeoutUpdated', payload: false });
      setUploadProgress(0);
      setRndFileKey(Math.random()); //? This is used to reset the file input after a file is uploaded.
      setIsUploading(false);
      loadingModalRef?.current?.close?.();
    }
  };

  const handleDeleteFileClick = file => {
    if (PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)) return;

    setFileToDelete(file);

    deleteFileModalRef?.current?.showModal();
  };

  const deleteFile = async () => {
    if (PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)) return;

    setIsDeletingFile(true);

    try {
      await deleteUserProjectFile(user.id, projectId, fileToDelete.id);
      const fetchedProject = await getUserProject(user.id, projectId);

      const updatedActiveProjectFilesData = {
        ...activeProjectFilesData,
        files: activeProjectFilesData.files.filter(file => file.id !== fileToDelete.id),
        totalItems: activeProjectFilesData.totalItems - 1
      };

      dashboardDispatch({ type: 'activeProjectFilesDataUpdated', payload: updatedActiveProjectFilesData });
      dashboardDispatch({ type: 'activeProjectUpdated', payload: fetchedProject }); //? Update project with new data

      toast.success('File successfully deleted.');
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      deleteFileModalRef?.current?.close?.();
      setIsDeletingFile(false);
      setFileToDelete(null);
    }
  };

  const handleDownloadFileClick = async file => {
    setFileToDownload(file);
    downloadFileModalRef?.current?.showModal();

    const onProgressCb = progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      setDownloadProgress(percentCompleted);
    };

    try {
      const downloadedFile = await downloadUserProjectFile(user.id, projectId, file.id, onProgressCb);
      const url = window.URL.createObjectURL(downloadedFile);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', file.displayName);

      document.body.appendChild(link);

      link.click();

      toast.success('File successfully downloaded.');

      setTimeout(function () {
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 200);
    } catch (err) {
      //? Do nothing, error is handled by the interceptor.
    } finally {
      downloadFileModalRef?.current?.close?.();
      setFileToDownload(null);
    }
  };

  const { files, totalItems } = activeProjectFilesData || {};
  const { nodes, focusAreas, file } = formData;

  const isFreeProject = activeProject.currentPriceInCents === 0;

  return (
    <>
      <Card className='mb-6' customWidthClasses='w-11/12' customContentClasses='items-center'>
        <div className='w-full flex flex-col sm:flex-row items-center justify-between mb-3'>
          <button
            type='button'
            className='btn btn-outline btn-xs mb-3 sm:mb-0'
            onClick={() => {
              navigate('/dashboard/projects');
            }}
          >
            <IoMdArrowRoundBack />
          </button>

          <div className='flex flex-row items-center mb-3 sm:mb-0'>
            <h1 className='text-center text-lg font-bold mr-2'>{activeProject.name}</h1>

            <div className={`badge badge-outline ${getBadgeColor(activeProject.status)}`}>{activeProject.status}</div>
          </div>

          <button
            className='btn btn-outline btn-error btn-xs'
            disabled={PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)}
            onClick={handleDeleteProjectClick}
          >
            <FaRegTrashAlt />
          </button>
        </div>

        <div className='w-full flex flex-row items-center justify-center mb-3'>
          <h3 className='text-center'>{activeProject.description}</h3>
        </div>

        {activeProject?.statusMessage && (
          <div className='w-full flex flex-col items-center justify-center mb-3'>
            <h3 className='text-center font-bold'>Status Message</h3>
            <p className='text-info'>{activeProject.statusMessage}</p>
          </div>
        )}

        <div className='w-full flex flex-row items-center justify-between'>
          <h3 className='text-left'>Created On: {new Date(activeProject.createdDate).toDateString()}</h3>
          <h3 className='text-right'>Last Updated: {new Date(activeProject.updatedDate).toDateString()}</h3>
        </div>

        {[PROJECT_STATUSES.open, PROJECT_STATUSES.pending].includes(activeProject.status) &&
          activeProject.totalNodes > 0 && (
            <div className='w-full flex flex-col items-center justify-center mt-3'>
              {activeProject.status === PROJECT_STATUSES.open && (
                <h3 className='text-center text-md font-bold mb-3'>
                  Price: {isFreeProject ? 'Free' : `${activeProject.currentPriceInCents / 100}€`}
                </h3>
              )}

              <div className='flex flex-col justify-center items-center'>
                {(!activeProjectPaymentData || activeProjectPaymentData.status !== PAYMENT_STATUSES.processing) && (
                  <button
                    className='btn btn-outline btn-primary w-60'
                    disabled={isCheckingOut}
                    onClick={checkoutProject}
                  >
                    {isCheckingOut ? (
                      <span className='loading loading-dots loading-xs mx-2'></span>
                    ) : !isFreeProject ? (
                      activeProject.status === PROJECT_STATUSES.pending ? (
                        'Resume Checkout'
                      ) : (
                        'Checkout'
                      )
                    ) : (
                      'Submit'
                    )}
                  </button>
                )}

                {activeProjectPaymentData && activeProjectPaymentData.status === PAYMENT_STATUSES.processing && (
                  <>
                    <Loader customText='Processing Payment' />

                    <p className='text-center text-xs'>
                      This may take a while depending on payment method. Project status will be automatically updated
                      once payment succeeds.
                    </p>
                  </>
                )}

                {activeProject.status === PROJECT_STATUSES.open && (
                  <p className='mt-3 text-center text-xs'>
                    No files can be added to the project after {!isFreeProject ? 'checkout' : 'submission'}.
                  </p>
                )}
              </div>
            </div>
          )}

        <dialog ref={checkoutModalRef} id='checkout_modal' className='modal modal-bottom sm:modal-middle'>
          <div className='modal-box'>
            {isFreeProject && (
              <>
                <h3 className='text-center font-bold text-lg'>Submitting project</h3>
                <p className='text-center mt-3'>Please, remain on this page while your project is being submitted.</p>
                <p className='text-center mt-3'>This window will automatically close on completion.</p>
              </>
            )}

            {!isFreeProject && !allowCheckout && isCheckingOut && <Loader customText='Retrieving checkout data' />}

            {!isFreeProject && allowCheckout && <ProjectCheckout closeModal={handleCloseCheckoutModal} />}
          </div>
        </dialog>

        <dialog ref={deleteProjectModalRef} id='delete_project_modal' className='modal modal-bottom sm:modal-middle'>
          <div className='modal-box'>
            <form method='dialog'>
              <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'>✕</button>
            </form>
            <h3 className='text-center font-bold text-lg mb-3'>Delete {activeProject.name}</h3>
            <p className='text-center mb-3'>
              This action is permanent! All data and files linked to this project will also be deleted.
            </p>
            <p className='text-center mb-3'>
              If you wish to keep the files, please download them before deleting the project.
            </p>
            <p className='text-center mb-3 font-bold'>
              The project and its files cannot be restored from our side after deletion.
            </p>
            {!isDeletingProject && (
              <div className='flex justify-around items-center'>
                <button className='btn btn-outline btn-error' onClick={deleteProject}>
                  Delete Project
                </button>
                <button
                  className='btn btn-outline btn-neutral'
                  onClick={() => deleteProjectModalRef?.current?.close?.()}
                >
                  Cancel
                </button>
              </div>
            )}
            {isDeletingProject && <Loader customText='Deleting project' />}
          </div>
        </dialog>
      </Card>

      <Card customWidthClasses='w-11/12'>
        {isFetchingFiles && <Loader customText='Retrieving files' />}

        {!isFetchingFiles && (
          <>
            <h1 className='text-center font-bold'>
              {totalItems > 0
                ? `${totalItems} File${totalItems > 1 ? 's' : ''}`
                : 'This project does not have any files yet.'}
            </h1>

            {totalItems > 0 && (
              <>
                <dialog
                  ref={downloadFileModalRef}
                  id='download_file_modal'
                  className='modal modal-bottom sm:modal-middle'
                >
                  <div className='modal-box'>
                    <h3 className='text-center font-bold text-lg'>Downloading {fileToDownload?.displayName}</h3>
                    <p className='text-center mt-3'>Please, remain on this page until the end of the upload.</p>
                    <p className='text-center mt-3'>This window will automatically close on completion.</p>
                    <p className='mt-3 font-bold flex flex-row justify-center items-end'>
                      {downloadProgress === 0 && (
                        <>
                          <span>Preparing file</span>
                          <span className='loading loading-dots loading-xs mx-2'></span>
                          <span>This may take a while for larger files.</span>
                        </>
                      )}
                      {downloadProgress > 0 && <>{downloadProgress}% Downloaded</>}
                    </p>
                    <progress className='progress progress-info w-full' value={downloadProgress} max='100'></progress>
                  </div>
                </dialog>

                {/* TODO: Add pagination */}
                <div className='overflow-x-scroll max-w-full'>
                  <table className='table table-sm sm:table-md xl:table-lg text-center'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Nodes</th>
                        <th>Focus Areas</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {files.map(file => (
                        <tr key={file.id}>
                          <td>{file.displayName}</td>
                          <td>{file.nodes || 'N/A'}</td>
                          <td className='whitespace-pre-wrap'>{file.focusAreas || 'N/A'}</td>
                          <td>{new Date(file.updatedDate).toDateString()}</td>
                          <td>
                            <div className={`badge badge-outline ${getBadgeColor(file.status)}`}>{file.status}</div>
                          </td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-outline btn-info btn-xs'
                              onClick={() => handleDownloadFileClick(file)}
                            >
                              <FaCloudDownloadAlt />
                            </button>
                          </td>
                          <td>
                            <button
                              type='button'
                              disabled={PROJECT_STATUSES_PREVENT_CHANGES.includes(activeProject.status)}
                              className='btn btn-outline btn-error btn-xs'
                              onClick={() => handleDeleteFileClick(file)}
                            >
                              <FaRegTrashAlt />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <dialog ref={deleteFileModalRef} id='delete_file_modal' className='modal modal-bottom sm:modal-middle'>
                  <div className='modal-box'>
                    <form method='dialog'>
                      <button className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'>✕</button>
                    </form>
                    <h3 className='text-center font-bold text-lg mb-3'>Delete {fileToDelete?.displayName}</h3>
                    <p className='text-center mb-3'>This action is permanent!</p>
                    <p className='text-center mb-3'>
                      If you wish to keep the file, please download it before deleting it.
                    </p>
                    <p className='text-center mb-3 font-bold'>
                      The file cannot be restored from our side after deletion.
                    </p>

                    {!isDeletingFile && (
                      <div className='flex justify-around items-center'>
                        <button className='btn btn-outline btn-error' onClick={deleteFile}>
                          Delete File
                        </button>
                        <button
                          className='btn btn-outline btn-neutral'
                          onClick={() => deleteFileModalRef?.current?.close?.()}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                    {isDeletingFile && <Loader customText='Deleting file' />}
                  </div>
                </dialog>
              </>
            )}
          </>
        )}
      </Card>

      {!isFetchingFiles && totalItems < MAX_PROJECT_FILES / 2 && activeProject.status === PROJECT_STATUSES.open && (
        <Card customWidthClasses='w-11/12' className='mt-6'>
          <dialog ref={loadingModalRef} id='loading_modal' className='modal modal-bottom sm:modal-middle'>
            <div className='modal-box'>
              <h3 className='text-center font-bold text-lg'>Uploading file</h3>
              <p className='text-center mt-3'>Please, remain on this page until the end of the upload.</p>
              <p className='text-center mt-3'>This window will automatically close on completion.</p>
              <p className='mt-3 font-bold flex flex-row justify-center items-end'>
                {uploadProgress >= 99 && (
                  <>
                    <span>Finishing</span>
                    <span className='loading loading-dots loading-xs mx-2'></span>
                    <span>This may take a while for larger files.</span>
                  </>
                )}
                {uploadProgress < 99 && <>{uploadProgress}% Done</>}
              </p>
              <progress className='progress progress-info w-full' value={uploadProgress} max='100'></progress>
            </div>
          </dialog>

          <h1 className='text-center font-bold'>Upload File</h1>
          <form className='w-full sm:w-2/3 xl:w-1/2' onSubmit={handleFormSubmit}>
            <label className='form-control'>
              <div className='label'>
                <span className='label-text text-base'>Areas to focus on:</span>
              </div>
              <textarea
                className='textarea textarea-bordered w-full text-base'
                placeholder={`Area 1: ...;\nArea 2: ...;`}
                id='focusAreas'
                onChange={handleFormChange}
                value={focusAreas}
              ></textarea>
            </label>

            <label className='form-control'>
              <div className='label'>
                <span className='label-text text-base'>Number of nodes in file:</span>
              </div>
              <input
                type='number'
                placeholder='Number of nodes'
                id='nodes'
                className='input input-bordered w-full'
                onChange={handleFormChange}
                value={nodes}
              />
              <div className='label'>
                <span className='label-text-alt text-error text-center'>
                  <b>NB!</b> Number of nodes provided above must match the number of nodes in the file being uploaded.
                  If one or more files in the project has an incorrect number of nodes, the project will be{' '}
                  <b>canceled</b>.
                </span>
              </div>
            </label>

            <input
              type='file'
              id='file'
              className='file-input file-input-bordered w-full mb-3'
              key={rndFileKey}
              onChange={handleFormChange}
            />

            <button
              type='submit'
              className='btn btn-primary w-full mb-3'
              disabled={nodes === '' || nodes <= 0 || focusAreas === '' || !file || isUploading}
            >
              {isUploading ? <span className='loading loading-dots loading-md'></span> : 'Upload'}
            </button>
          </form>
        </Card>
      )}
    </>
  );
};

export default UserProjectDetails;
