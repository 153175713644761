import { useOutletContext } from 'react-router-dom';

import UserProjects from '@Pages/dashboard/user/UserProjects';
import AdminProjects from '@Pages/dashboard/admin/AdminProjects';

const ProjectsList = () => {
  const [displayAdminComponents] = useOutletContext();

  return displayAdminComponents ? <AdminProjects /> : <UserProjects />;
};

export default ProjectsList;
