import { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import DashboardContext from '@Contexts/dashboard/DashboardContext';
import { getUserProject, getUserProjectPayment, cancelUserProjectPayment } from '@Contexts/dashboard/DashboardActions';

import { getStripePromise } from '@Utils/apiHelpers';
import { PAYMENT_STATUSES } from '@Utils/constants';

import { toast } from 'react-toastify';

import Loader from '@Components/ui/Loader';
import CheckoutForm from '@Components/stripe/CheckoutForm';

import { Elements } from '@stripe/react-stripe-js';

const stripePromise = getStripePromise();

const ProjectCheckout = ({ closeModal = () => {} }) => {
  const { user } = useContext(AuthContext);
  const { activeProjectPaymentData, dispatch } = useContext(DashboardContext);

  const [isFetchingProjectPayment, setIsFetchingProjectPayment] = useState(true);
  const [isCancelingPayment, setIsCancelingPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState('');

  const { projectId } = useParams();

  const returnUrl = window.location.href;

  useEffect(() => {
    if (activeProjectPaymentData === null || activeProjectPaymentData.projectId !== projectId) {
      const fetchProjectPaymentData = async () => {
        try {
          const fetchedProjectPaymentData = await getUserProjectPayment(user.id, projectId);

          dispatch({ type: 'activeProjectPaymentDataUpdated', payload: fetchedProjectPaymentData });
        } catch (err) {
          closeModal();
        }
      };

      fetchProjectPaymentData();
    } else if (activeProjectPaymentData && !activeProjectPaymentData.clientSecret) {
      toast.warn('Project is not ready for checkout!');

      closeModal();
    } else if (activeProjectPaymentData && activeProjectPaymentData.status === PAYMENT_STATUSES.processing) {
      toast.info('Your payment is processing.');

      closeModal();
    } else {
      setClientSecret(activeProjectPaymentData.clientSecret);
      setIsFetchingProjectPayment(false);
    }
  }, [activeProjectPaymentData, dispatch, projectId, user.id, closeModal]);

  const handleCancelPayment = async () => {
    if (isCancelingPayment) return;

    setIsCancelingPayment(true);

    try {
      await cancelUserProjectPayment(user.id, projectId);

      const fetchedProject = await getUserProject(user.id, projectId);

      closeModal();

      dispatch({ type: 'activeProjectUpdated', payload: fetchedProject }); //? Update project with new data
      dispatch({ type: 'activeProjectPaymentDataUpdated', payload: null });
    } catch (err) {
      closeModal();
    } finally {
      setIsCancelingPayment(false);
    }
  };

  if (isFetchingProjectPayment) return <Loader customText='Retrieving checkout data' />;

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe'
    }
  };

  return (
    <>
      <h3 className='text-center font-bold text-lg mb-6'>Project Payment</h3>

      <p className='text-center mb-6'>
        Total project price:{' '}
        <span className='font-bold text-lg'>{activeProjectPaymentData.currentPriceInCents / 100}€</span>
      </p>

      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm cancelPayment={handleCancelPayment} returnUrl={returnUrl} />
      </Elements>
    </>
  );
};

export default ProjectCheckout;
