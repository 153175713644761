import { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import { login } from '@Contexts/auth/AuthActions';

import { toast } from 'react-toastify';

import Card from '@Components/ui/Card';

const Login = () => {
  const { user, dispatch } = useContext(AuthContext);

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      toast.warn('Already logged in!');
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const activeBtnClass = 'btn-primary btn-active';

  const handleFormChange = ({ target }) => {
    setFormData(prev => ({ ...prev, [target.id]: target.value }));
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = await login(formData.email, formData.password);

      setIsLoading(false);

      if (user) {
        toast.success('Successfully logged in.');

        dispatch({ type: 'userUpdated', payload: user });

        navigate('/dashboard');
      } else {
        setFormData({ email: '', password: '' });

        toast.error('An error has occurred. Please try logging in again.');
      }
    } catch (err) {
      //? No need to display error notification since it is handled in interceptor.
      setIsLoading(false);
      setFormData(prev => ({ ...prev, password: '' }));
    }
  };

  const { email, password } = formData;

  return (
    <Card>
      <div className='join w-full'>
        <button className={`join-item btn btn-outline w-1/2 ${activeBtnClass}`}>Login</button>
        <button onClick={() => navigate('/auth/register')} className={`join-item btn btn-outline btn-accent w-1/2`}>
          Register
        </button>
      </div>

      <form className='w-full' onSubmit={handleFormSubmit}>
        <input
          type='email'
          placeholder='Email Address'
          id='email'
          className='input input-bordered w-full mt-1 mb-3'
          onChange={handleFormChange}
          value={email}
        />

        <label className='form-control'>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='Password'
            id='password'
            className='input input-bordered w-full'
            value={password}
            onChange={handleFormChange}
          />

          <div className='label'>
            <span className='label-text-alt'>
              {/* TODO: Redirect to proper password reset form */}
              <button
                type='button'
                className='btn btn-link btn-xs text-accent p-0 text-left'
                onClick={() => navigate('/auth/reset-password')}
              >
                Forgot password?
              </button>
            </span>
            <span className='label-text-alt'>
              <button
                type='button'
                className='btn btn-link btn-xs text-neutral p-0 text-right'
                onClick={() => setShowPassword(prev => !prev)}
              >
                {showPassword ? 'Hide password' : 'Show password'}
              </button>
            </span>
          </div>
        </label>

        <button
          type='submit'
          className='btn btn-primary w-full mb-3'
          disabled={Object.values(formData).indexOf('') !== -1 || isLoading}
        >
          {isLoading ? <span className='loading loading-dots loading-md'></span> : 'Login'}
        </button>

        <p className='text-center text-neutral'>
          No account?{' '}
          <Link to='/auth/register' className='text-accent'>
            Register here!
          </Link>
        </p>
      </form>
    </Card>
  );
};

export default Login;
