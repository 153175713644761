export const COMPANY_NAME = 'Artec';
export const SUPPORT_EMAIL = 'support@codenexus.eu';

//* -- Nav Menu Constants --
export const NAV_MENU_ITEMS = [
  { displayName: 'Home', key: 'home', path: '/' },
  { displayName: 'Technology', key: 'technology', path: '/technology' },
  { displayName: 'About Us', key: 'about-us', path: '/about-us' },
  { displayName: 'Pricing', key: 'pricing', path: '/pricing' }
];
//* ----

//* -- API Constants --
const API_VERSION = 'v1';
const API_BASE_URL = 'https://artec-solutions.lm.r.appspot.com/api';

export const API_URL = `${API_BASE_URL}/${API_VERSION}`;

export const STRIPE_API_KEY =
  'pk_test_51Ohv0SIjd1eDxG0hg95m8mXde4wmDsF51s94aBZlaYLVRLGTwdhryM2YdQ3b3g513XceZufDrqx96fmtbXFnTOh900t4wVLyLh';
//* ----

//* -- Permissions Constants --
export const PERMISSION_GROUPS = {
  user: 'User',
  admin: 'Admin'
};

const COMMON_MENU_ITEMS = [
  { name: 'Projects', to: '/dashboard/projects', hasSubRoutes: true },
  { name: 'Account', to: '/dashboard/account' }
];

export const ADMIN_MENU_ITEMS = [{ name: 'Summary', to: '/dashboard' }].concat(COMMON_MENU_ITEMS.concat([]));

export const USER_MENU_ITEMS = [].concat(COMMON_MENU_ITEMS.concat([]));
//* ----

//* -- Projects Constants --
export const PROJECT_STATUSES = {
  open: 'Open',
  pending: 'Payment Pending',
  paid: 'Paid',
  processing: 'Processing',
  completed: 'Completed',
  cancelled: 'Cancelled'
};

export const FILE_STATUSES = {
  received: 'Received',
  processing: 'Processing',
  completed: 'Completed'
};

export const PROJECT_STATUSES_PREVENT_CHANGES = [
  PROJECT_STATUSES.pending,
  PROJECT_STATUSES.paid,
  PROJECT_STATUSES.processing
];

export const PAYMENT_STATUSES = {
  pending: 'Pending',
  processing: 'Processing',
  succeeded: 'Succeeded',
  failed: 'Failed',
  refunded: 'Refunded'
};
//* ----

//* -- Style Constants --
export const AUTH_BG_GRADIENT_CLASSES = 'bg-gradient-to-r from-green-400 to-blue-500';

export const MAX_SHORT_DESCRIPTION_LENGTH = 25;
//* ----

//* -- Validation Constants --
export const PASSWORD_REGEX = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

export const MAX_PROJECT_FILES = 10;
//* ----
