import HeroSection from '@Components/ui/HeroSection';

const NotFound = () => {
  return (
    <HeroSection fullHeight={true}>
      <h1>404 - Page Not Found</h1>
    </HeroSection>
  );
};

export default NotFound;
