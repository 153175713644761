import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import { register } from '@Contexts/auth/AuthActions';

import { PASSWORD_REGEX } from '@Utils/constants';

import { toast } from 'react-toastify';

import Card from '@Components/ui/Card';

const Register = () => {
  const { user, dispatch } = useContext(AuthContext);

  const [formData, setFormData] = useState({ name: '', email: '', password: '', passwordConfirm: '' });
  const [passwordFitsRules, setPasswordFitsRules] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      toast.warn('Already logged in!');
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const activeBtnClass = 'btn-primary btn-active';

  const handleFormChange = ({ target }) => {
    if (target.id === 'password') {
      setPasswordFitsRules(PASSWORD_REGEX.test(target.value));
    }

    setFormData(prev => ({ ...prev, [target.id]: target.value }));
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = await register(formData.name, formData.email, formData.password);

      setIsLoading(false);

      if (user) {
        toast.success('Account successfully created. You have been logged in.');

        dispatch({ type: 'userUpdated', payload: user });

        navigate('/dashboard');
      } else {
        setPasswordFitsRules(false);
        setFormData({ name: '', email: '', password: '', passwordConfirm: '' });

        toast.error('An error has occurred. Please try registering again.');
      }
    } catch (err) {
      //? No need to display error notification since it is handled in interceptor.
      setIsLoading(false);
      setPasswordFitsRules(false);
      setFormData(prev => ({ ...prev, password: '', passwordConfirm: '' }));
    }
  };

  const { name, email, password, passwordConfirm } = formData;

  return (
    <Card>
      <div className='join w-full'>
        <button onClick={() => navigate('/auth/login')} className={`join-item btn btn-outline btn-accent w-1/2`}>
          Login
        </button>
        <button className={`join-item btn btn-outline w-1/2 ${activeBtnClass}`}>Register</button>
      </div>

      <form className='w-full' onSubmit={handleFormSubmit}>
        <input
          type='text'
          placeholder='Full Name'
          id='name'
          className='input input-bordered w-full mb-3 mt-1'
          onChange={handleFormChange}
          value={name}
        />

        <input
          type='email'
          placeholder='Email Address'
          id='email'
          className='input input-bordered w-full mb-3'
          onChange={handleFormChange}
          value={email}
        />

        <label className='form-control'>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='Password'
            id='password'
            className={`input input-bordered w-full ${password?.length > 0 && !passwordFitsRules ? '' : 'mb-3'}`}
            value={password}
            onChange={handleFormChange}
          />

          {password?.length > 0 && !passwordFitsRules && (
            <div className='label'>
              <span className='label-text-alt text-neutral'>
                <span>Password must match the following criteria:</span>
                <ul className='mt-1'>
                  <li>- At least 1 lower case letter</li>
                  <li>- At least 1 upper case letter</li>
                  <li>- At least 1 number</li>
                  <li>- At least 1 special character</li>
                  <li>- At least 8 characters long</li>
                </ul>
              </span>
            </div>
          )}
        </label>

        <label className='form-control'>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='Confirm Password'
            id='passwordConfirm'
            className={`input input-bordered w-full`}
            value={passwordConfirm}
            onChange={handleFormChange}
          />

          <div className='label'>
            <span className='label-text-alt text-neutral'>
              {passwordConfirm?.length > 0 && passwordConfirm !== password
                ? 'Password and Confirm password must match!'
                : ''}
            </span>

            <span className='label-text-alt'>
              <button
                type='button'
                className='btn btn-link btn-xs text-neutral p-0 text-right'
                onClick={() => setShowPassword(prev => !prev)}
              >
                {showPassword ? 'Hide password' : 'Show password'}
              </button>
            </span>
          </div>
        </label>

        <button
          type='submit'
          className='btn btn-primary w-full'
          disabled={
            Object.values(formData).indexOf('') !== -1 ||
            !passwordFitsRules ||
            passwordConfirm !== password ||
            isLoading
          }
        >
          {isLoading ? <span className='loading loading-dots loading-md'></span> : 'Register'}
        </button>
      </form>
    </Card>
  );
};

export default Register;
