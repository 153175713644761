import { useContext, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import AuthContext from '@Contexts/auth/AuthContext';
import DashboardContext from '@Contexts/dashboard/DashboardContext';

import { PERMISSION_GROUPS, ADMIN_MENU_ITEMS, USER_MENU_ITEMS } from '@Utils/constants';

//* UI Components
import DashboardNavbar from '@Components/dashboard/DashboardNavbar';
import DashboardDrawer from '@Components/dashboard/DashboardDrawer';

const DashboardLayout = () => {
  const { user, dispatch: authDispatch } = useContext(AuthContext);
  const { dispatch: dashboardDispatch } = useContext(DashboardContext);

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [forceUserRole, setForceUserRole] = useState(window.localStorage.getItem('forceUserRole') === 'true' || false);

  if (!user?.emailVerified) return <Navigate to='/auth/verify/email' />;

  const activeRole = forceUserRole ? PERMISSION_GROUPS.user : user?.permGroup;

  const hasAdminRole = user?.permGroup === PERMISSION_GROUPS.admin;
  const displayAdminComponents = activeRole === PERMISSION_GROUPS.admin;

  const menuItems = getMenuItems(activeRole);

  const handleLogout = () => {
    authDispatch({ type: 'logout' });
  };

  const handleSidebarToggle = () => {
    setSidebarOpened(!sidebarOpened);
  };

  const handleUserRoleToggle = () => {
    dashboardDispatch({ type: 'reset' });
    window.localStorage.setItem('forceUserRole', !forceUserRole);
    setForceUserRole(!forceUserRole);
  };

  return (
    <>
      <DashboardNavbar
        hasAdminRole={hasAdminRole}
        handleLogout={handleLogout}
        sidebarOpened={sidebarOpened}
        forceUserRole={forceUserRole}
        handleSidebarToggle={handleSidebarToggle}
        handleUserRoleToggle={handleUserRoleToggle}
      />
      <main>
        <DashboardDrawer menuItems={menuItems} handleSidebarToggle={handleSidebarToggle} sidebarOpened={sidebarOpened}>
          <Outlet context={[displayAdminComponents]} />
        </DashboardDrawer>
      </main>
    </>
  );
};

//* -- Helpers --
const getMenuItems = permGroup => {
  switch (permGroup) {
    case PERMISSION_GROUPS.admin:
      return ADMIN_MENU_ITEMS;
    case PERMISSION_GROUPS.user:
    default:
      return USER_MENU_ITEMS;
  }
};
//* ----

export default DashboardLayout;
